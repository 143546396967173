import { createSlice } from "@reduxjs/toolkit";
import { AsyncState } from "../../types";
import { SupplyState, SupplyStateDrawers } from "./type";

const initialState: SupplyState = {
  drawers: {
    [SupplyStateDrawers.SUPPLIES]: {
      open: false,
      data: [],
      kitchen: "Santa Monica",
      title: "",
      status: AsyncState.IDLE,
    },
  },
};
const suppliesStateSlice = createSlice({
  name: "supplies-state-slice",
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.drawers[SupplyStateDrawers.SUPPLIES].open = action.payload;
      state.drawers[SupplyStateDrawers.SUPPLIES].status = AsyncState.SUCCESS;
    },
  },
});

export const { setDrawer } = suppliesStateSlice.actions;
export default suppliesStateSlice.reducer;
