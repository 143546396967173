import { Schedule } from "../../gateways/amplify/types";
import {
  CoveragePolygon,
  Platform,
  PlatformConfig,
  StatusStore,
} from "./Store.entity";

export enum BrandStateAction {
  ON = "ON",
  OFF = "OFF",
}

export enum BrandStatusOutTimeType {
  ON_OK = "on_ok",
  OFF_OK = "off_ok",
  ON_ATENTION = "on_attention_time",
  OFF_ATENTION = "off_attention_time",
  FALSE_ALARM = "false_alarm",
  NO_VALIDATE = "no_validate",
}

export type Coverage = {
  reached: string;
  points: {
    total: number;
    enable: number;
  };
};

export type BrandState = {
  id?: string | null;
  enabled: boolean | null;
  visible: boolean | null;
  message: string;
  storeId: string | null;
  lastUpdate: Date | null;
  schedule?: Schedule | null;
  status_atention?: BrandStatusOutTimeType | null;
  label_atention?: string | null;
  coverage: CoveragePolygon | null;
  platformConfig: PlatformConfig | null;
  activeOrders?: number | null;
  reasonState: string | null;
  status?: StatusStore;
};

export interface BrandScheduleLabel {
  storeId: string | null;
  label: string;
  action: BrandStateAction;
}

interface Brand {
  id: string | null;
  name: string | null;
  state: {
    [platform in Platform]: BrandState | null;
  };
}

export interface BrandSelect {
  id: string;
  name: string;
  countries: Array<string>;
}

export default Brand;
