export enum OrderPageLocale {
  TITLE = "order.page.title",
  DESCRIPTION = "order.page.description",
  COOKING = "order.page.cooking",
  PACKING = "order.page.packing",
  TO_DELIVER = "order.page.to_deliver",
  ORDER_RT = "order.page.order_rt",
  ORDER_WITHOUT_RT = "order.page.order_without_rt",
  ORDER_LATER = "order.page.order_later",
  PEOPLE_IN_KITCHEN_RESUMEN = "order.page.resumen",
  PEOPLE_IN_KITCHEN = "order.page.people_in_kitchen",

  //grid
  KITCHEN = "order.grid.kitchen",
  ORDER_ACTIVE = "order.grid.active",
  LAST_UPDATE = "order.grid.last_update",
  IN_STATION = "order.grid.in_station",
  IN_QUEUE = "order.grid.queue",
  OK = "order.grid.ok",
  ATENTION = "order.grid.atention",

  //generic
  PLATFORM = "order.platform",
  BRAND = "order.brand",
  TOTAL_ORDERS = "order.total_orders",
  MAIN_PLATE = "order.main_plate",
  PERSONAL = "order.personal",
  SCHEDULE_IN = "order.schedule_in",
  DATE_IN = "order.date_in",
  HOURS_IN_KITCHEN = "order.hour_in_kitchen",

  //drawers
  //orders actives
  DRAWER_ORDERS_TITLE = "order.drawer.title",
  DRAWER_ORDERS_DESCRIPTION = "order.drawer.description",

  DRAWER_DELIVER_TITLE = "order.drawer.deliver.title",
  DRAWER_DELIVER_DESCRIPTION = "order.drawer.deliver.description",

  DRAWER_PEOPLE_TITLE = "order.drawer.people.title",
  DRAWER_PEOPLE_DESCRIPTION = "order.drawer.people.description",

  PEOPLE_CARD_TITLE = "order.people.card.title",
  PEOPLE_CARD_DESCRIPTION = "order.poeple.card.description",
  PEOPLE_CARD_CORP_TITLE = "order.card.corp.title",
  PEOPLE_CARD_CORP_DESCRIPTION = "order.card.corp.description",
}
