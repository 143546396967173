import Kitchen from "../../entities/Kitchen.enitity";
import { Platform } from "../../entities/Store.entity";
import { KitchenProcessStrategy } from "../../interfaces/strategy/kitchen.strategy";

class OrderKitchenProcess implements KitchenProcessStrategy {
  execute(data: Kitchen[]): Kitchen[] {
    return data.sort(
      (a, b) =>
        b.brands.filter((brand) =>
          Object.keys(brand.state).some(
            (key) => brand.state[key as Platform]?.enabled === false
          )
        ).length -
        a.brands.filter((brand) =>
          Object.keys(brand.state).some(
            (key) => brand.state[key as Platform]?.enabled === false
          )
        ).length
    );
  }
}
export default OrderKitchenProcess;
