import { createTheme } from "@mui/material/styles";

const breakpointsTheme = createTheme();

const theme = createTheme({
  breakpoints: {
    values: {
      ...breakpointsTheme.breakpoints.values,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#121F56",
      light: "#3C81DB",
      dark: "#121F56",
    },
    secondary: {
      main: "#1DB3D1",
      light: "#B3EFFF",
      dark: "#052550",
    },
    text: {
      primary: "#232323",
      secondary: "#7B7B7B",
    },
    background: {
      default: "#F3F8FF",
    },
    divider: "#A7A7A7",
    error: { main: "#D32F2F", light: "#EF5350", dark: "#C62828" },
    warning: { main: "#ED6C02", light: "#FF9800" },
    success: { main: "#57CA00", light: "#4CAF50", dark: "#1B5E20" },
    info: { main: "#1DB3D1", light: "#03A9F4" },
  },
  typography: {
    h1: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 300,
      fontSize: "40px",
      lineHeight: "50px",
    },
    h2: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 300,
      fontSize: "34px",
      lineHeight: "40px",
    },
    h3: {
      fontFamily: "Poppins, Inter, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "30px",
      lineHeight: "36px",
    },
    h4: {
      fontFamily: "Poppins, Inter, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: "32px",
    },
    h5: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "26px",
    },
    h6: {
      fontFamily: "Poppins, Inter, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
    },
    body1: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "18px",
    },
    body2: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16px",
    },
    subtitle1: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "24px",
    },
    subtitle2: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
    },
    caption: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    overline: {
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  mixins: {
    toolbar: {
      height: 56,
    },
  },
});

export default theme;
