import React from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import config from "config.provider";
import useAppDispatch from "../hooks/useAppDispatch";
import useAppSelector from "../hooks/useAppSelector";
import { AsyncState } from "../../store/types";
import { getSession } from "../../store/slices/sessionSlice";
import { getUser } from "../../store/slices/user.slice";
import { setUserIdAmplitude } from "../../common/utils/amplitude";

interface ProtectedRoute {
  children: React.ReactNode;
}

const ProtectedRoutes = (props: ProtectedRoute) => {
  const { children } = props;

  const location = useLocation();

  const [userToken, setUserToken] = React.useState<string | null>(null);
  const [userId, setUserId] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const sessionStatus = useAppSelector((state) => state.session.status);
  const session = useAppSelector((state) => state.session.session);

  React.useEffect(() => {
    setLoading(true);
    const env = config.env;
    const cookieUserId = Cookies.get(`${env}.userId`);
    const cookieLoginToken = Cookies.get(`${env}.loginToken`);

    if (cookieUserId && cookieLoginToken) {
      setUserId(cookieUserId);
      setUserToken(cookieLoginToken);
      setUserIdAmplitude(cookieUserId);
    } else {
      window.location.href = `${config.kitchenDisplay.url}/login?redirect=${config.kitchenMonitor.url}${location.pathname}`;
    }
    setLoading(false);
  }, [location.pathname]);

  React.useEffect(() => {
    if (sessionStatus === AsyncState.IDLE) {
      dispatch(getSession());
    }
  }, [sessionStatus, dispatch]);
  React.useEffect(() => {
    if (session) {
      dispatch(getUser(session));
    }
  }, [session, dispatch]);

  return (
    <>
      {loading && "Cargando.."}
      {userToken && userId && children}
    </>
  );
};

export default ProtectedRoutes;
