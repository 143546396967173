import { configureStore } from "@reduxjs/toolkit";
import dataTableSlice from "./slices/dataTableSlice";

import filterReducer from "./slices/filterSlice";
import ordersActiveSlice from "./slices/ordersActiveSlice";
import storeStateReducer from "./slices/storeStateSlice";
import orderStateSlice from "./slices/orderStateSlice";
import commonSlice from "./slices/commonSlice";
import mapsSlice from "./slices/mapsStateSlice";
import sessionSlice from "./slices/sessionSlice";
import userSlice from "./slices/user.slice";
import screenSlice from "./slices/screen.slice";
import menuSlice from "./slices/menu.slice";
import supplySlice from "./slices/suppliesStateSlice";

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    datatable: dataTableSlice,
    orderActive: ordersActiveSlice,
    storeState: storeStateReducer,
    orderState: orderStateSlice,
    commonState: commonSlice,
    mapsState: mapsSlice,
    session: sessionSlice,
    user: userSlice,
    screen: screenSlice,
    menu: menuSlice,
    supplySlice: supplySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "storeState/getKitchensState/fulfilled",
          "storeState.kitchenState.baseData",
          "store-state/setDrawerKitchenData",
          "store-state/setFilteredData",
          "mapsState/getKitchensState",
        ],
        ignoredPaths: [
          "storeState.kitchenState.data",
          "storeState.drawers.storeStateColumnVisibilityDrawer.data.kitchen.brands",
          "storeState.kitchenState.baseData",
          "storeState.kitchenState.filteredData",
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
