interface ModuleConfig {
  url: string | null;
  apiBaseUrl: string | null;
}
interface ModuleConfigAWS {
  url: string | null;
  apiKey: string | null;
}

interface ModuleRealmMongo {
  appId: string;
  apiKey: string;
  serviceNameDev: string;
  serviceNameProd: string;
}
interface ModuleGoogleMAps {
  apiKey: string;
}
export interface ConfigProvider {
  env: string | null;
  kitchenStaff: ModuleConfig;
  kitchenDisplay: ModuleConfig;
  kitchenMonitor: ModuleConfig;
  kitchenInventory: ModuleConfig;
  menuManager: ModuleConfig;
  jeopardy: ModuleConfig;
  appsync: ModuleConfigAWS;
  realm: ModuleRealmMongo;
  maps: ModuleGoogleMAps;
}

const configProvider: ConfigProvider = {
  env: process.env.REACT_APP_ENV ?? null,
  kitchenStaff: {
    url: process.env.REACT_APP_KITCHEN_STAFF_URL ?? null,
    apiBaseUrl: null,
  },
  kitchenDisplay: {
    url: process.env.REACT_APP_KITCHEN_DISPLAY_URL ?? null,
    apiBaseUrl: null,
  },
  kitchenMonitor: {
    url: process.env.REACT_APP_KITCHEN_MONITOR_URL ?? null,
    apiBaseUrl: null,
  },
  kitchenInventory: {
    url: process.env.REACT_APP_KITCHEN_INVENTORY_URL ?? null,
    apiBaseUrl: process.env.REACT_APP_KITCHEN_INVENTORY_API_BASE_URL ?? null,
  },
  menuManager: {
    url: process.env.REACT_APP_KITCHEN_MENU_MANAGER_URL ?? null,
    apiBaseUrl: null,
  },
  appsync: {
    url: process.env.REACT_APP_APPSYNC_URL ?? null,
    apiKey: process.env.REACT_APP_APPSYNC_APIKEY ?? null,
  },
  jeopardy: {
    url: process.env.REACT_APP_JEOPARDY_URL ?? null,
    apiBaseUrl: null,
  },
  realm: {
    appId: process.env.REACT_APP_PRODUCT_MANAGER_APP_ID ?? "",
    apiKey: process.env.REACT_APP_REALM_API_KEY ?? "",
    serviceNameDev:
      process.env.REACT_APP_PRODUCT_MANAGER_SERVICE_NAME_DEV ?? "",
    serviceNameProd:
      process.env.REACT_APP_PRODUCT_MANAGER_SERVICE_NAME_PROD ?? "",
  },
  maps: {
    apiKey: process.env.REACT_APP_API_KEY_MAP ?? "",
  },
};

export default configProvider;
