import React from "react";
import { Box } from "@mui/material";
import PaperTable from "../../../../components/Datatable/PaperTable";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "./Columns";
import { rows } from "./Rows";
import DrawerSuppliesOff from "../Drawer/DrawerSuppliesOff";
const DataTable = () => {
  return (
    <Box
      sx={{
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        flexGrow: 1,
        gap: 2,
      }}
    >
      <PaperTable>
        <DataGrid rows={rows} columns={columns} pageSize={40} rowHeight={76} />
      </PaperTable>

      <DrawerSuppliesOff />
    </Box>
  );
};

export default DataTable;
