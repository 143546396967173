import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import Kitchen from "domain/entities/Kitchen.enitity";
import Store, {
  Platform,
  PlatformStore,
  StateInternal,
  StatePlatform,
} from "domain/entities/Store.entity";
import KitchenGateway from "domain/interfaces/gateways/kitchen.gateway";
import { BrandState } from "domain/entities/Brand.entity";

import * as queries from "./../graphql/queries";
import {
  KitchenBrand,
  KitchenItemQuery,
  ListKitchensQuery,
  ListStoresQuery,
  State,
  StoreQuery,
} from "../types";
import "./../amplifyClient";

const getStoreEnabledState = (
  state: StatePlatform | null | undefined
): boolean | null => {
  if (state === StatePlatform.OPEN) {
    return true;
  }

  return false;
};

type TypeStringNullUndefined = string | null | undefined;
const getStoreState = (
  id: TypeStringNullUndefined,
  state: State | null | undefined
): PlatformStore | null => {
  if (!state) {
    return null;
  }

  return {
    id: id ?? null,
    name: Platform.IFOOD,
    enabled: getStoreEnabledState(state.status?.platform),
    visible: state.enabled ?? null,
    schedule: state.schedule ?? null,
    lastUpdate: state.lastUpdate ? new Date(state.lastUpdate) : null,
    coverage: state.coverage ?? null,
    platformConfig: state.platformConfig,
    reasonState: state.status?.reason ?? null,
    status: state.status,
  };
};

const getPlaformStoreState = (
  store: PlatformStore | null
): BrandState | null => {
  if (!store) {
    return null;
  }

  return {
    enabled: store.enabled ?? null,
    visible: store.visible ?? null,
    message: "",
    lastUpdate: store.lastUpdate ?? null,
    schedule: store.schedule ?? null,
    storeId: store.id ?? null,
    coverage: store.coverage ?? null,
    platformConfig: store.platformConfig ?? null,
    reasonState: store.reasonState ?? null,
    status: store.status,
  };
};

class AmplifyKitchenGateway implements KitchenGateway {
  private items: Array<any> = [];
  private kitchensProduction: Array<any> = [];
  private token: String | null | undefined = "";

  async getQueryPagination(token: String | undefined | null) {
    const query = (await API.graphql({
      query: queries.ListStores,
      variables: {
        limit: 1500, //limit row per pagination
        nextToken: token,
      },
    })) as GraphQLResult<ListStoresQuery>;
    return await query;
  }

  async getKitchensProduction() {
    const queryResult: GraphQLResult<ListKitchensQuery> = (await API.graphql({
      query: queries.listKitchens,
      variables: {
        filter: { type: { eq: "PRODUCTION_CENTER" }, status: { eq: "ACTIVE" } },
        limit: 1500,
      },
    })) as GraphQLResult<ListKitchensQuery>;
    return (await queryResult.data?.listKitchens?.items) ?? [];
  }

  async buildItems(token: any) {
    if (typeof token == "string" || Boolean(token)) {
      const queryResult: GraphQLResult<ListStoresQuery> =
        await this.getQueryPagination(token);

      if (!queryResult.data?.listStores?.items) {
        throw new Error("AmplifyKitchenGateway.getKitchensState: query error");
      }

      const filterOnlyStoreAvailable =
        queryResult.data?.listStores?.items.filter(
          (store) =>
            store &&
            store.status &&
            (store?.status.internal === StateInternal.OPEN ||
              store?.status.internal === StateInternal.TEMP_CLOSED)
        );
      this.items.push(...filterOnlyStoreAvailable);
      this.token = queryResult.data?.listStores?.nextToken;
      await this.buildItems(this.token);
    }
  }

  setStateLegacy(store: StoreQuery): State | null | undefined {
    return {
      __typename: "State",
      coverage: store.coverage ?? null,
      enabled: store.enabled ?? false,
      lastUpdate: store.lastUpdate,
      platform: store.platform,
      schedule: store.schedule,
      state: store.state,
      type: null,
      reasonState: store.reasonState,
      universalId: store.universalId ?? "",
      status: store.status,
      platformConfig: {
        isMarketPlace: store.platformConfig?.isParentStore ?? false,
        isParentStore: store.platformConfig?.isMarketPlace ?? false,
        theoreticalCoverage: {
          area: store.platformConfig?.theoreticalCoverage?.area ?? 0,
          unit: store.platformConfig?.theoreticalCoverage?.unit ?? "",
        },
      },
    };
  }

  getFormatNameBrand(store: StoreQuery): string {
    return (
      store.brand?.brandName ??
      (store.brandId ?? "") +
        (store.platformConfig && store?.platformConfig?.isMarketPlace
          ? " MP"
          : "")
    );
  }
  getBrandName(store: StoreQuery): string {
    const brandName = store.brand?.brandName;
    const brandId = store.brandId ?? "";
    const isMarketPlace = store.platformConfig?.isMarketPlace;
    const marketPlaceSuffix = isMarketPlace ? " MP" : "";
    return brandName ?? brandId + marketPlaceSuffix;
  }

  runBuilderFormatLegacy(items: Array<any>): Array<KitchenBrand> {
    let kitchenBrands: Array<{
      kitchenId: TypeStringNullUndefined;
      brandId: TypeStringNullUndefined;
    }> = [];

    let listKitchenBrand: Array<KitchenBrand> = [];

    items.forEach((item: StoreQuery) => {
      if (
        !kitchenBrands.some(
          (i) => i.brandId === item.brandId && i.kitchenId === item.kitchenId
        )
      ) {
        kitchenBrands.push({
          kitchenId: item.kitchenId,
          brandId: item.brandId,
        });
      }
    });

    kitchenBrands.forEach((kb, index) => {
      let rows: Array<StoreQuery> = [];
      try {
        rows = items.filter(
          (s: StoreQuery) =>
            s.brandId === kb.brandId && s.kitchenId === kb.kitchenId
        );

        rows.forEach((store: StoreQuery) => {
          let schema: KitchenBrand = {
            __typename: "KitchenBrand",
            kitchen: null,
            universalId: index.toString(),
          };

          schema.kitchen = { ...store.kitchen };
          schema.kitchenId = store.kitchenId;
          schema.brandId = store.brandId;

          schema.kitchenName = store.kitchen ? store.kitchen.name : "";
          schema.city = store.city;
          schema.country = store.country;
          schema.storeId = store.warehouseId;
          schema.status = store.status;

          schema.brandName = this.getBrandName(store);

          switch (store.platform as Platform) {
            case Platform.RAPPI: {
              schema.rappiId = store.universalId;
              schema.rappiState = this.setStateLegacy(store);
              break;
            }
            case Platform.DIDI: {
              schema.didiId = store.universalId;
              schema.didiState = this.setStateLegacy(store);
              break;
            }
            case Platform.IFOOD: {
              schema.ifoodId = store.universalId;
              schema.ifoodState = this.setStateLegacy(store);
              break;
            }
            case Platform.UBER: {
              schema.uberId = store.universalId;
              schema.uberState = this.setStateLegacy(store);
              break;
            }
            case Platform.PEDIDOSYA: {
              schema.pedidosyaId = store.universalId;
              schema.pedidosyaState = this.setStateLegacy(store);
            }
          }
          listKitchenBrand.push(schema);
        });
      } catch (err) {
        console.error("error", err, kb, rows, rows[0]);
      }
    });
    return listKitchenBrand;
  }
  async getKitchensState() {
    await this.buildItems(this.token);

    const groupBrandsByKitchen: { [kitchen: string]: Array<Store> } = {};

    let kitchensStateItems = this.runBuilderFormatLegacy(this.items);

    this.kitchensProduction = await this.getKitchensProduction();
    this.kitchensProduction.forEach((item: KitchenItemQuery, index) => {
      let schemaTemp: KitchenBrand = {
        __typename: "KitchenBrand",
        kitchen: {
          kitchenId: item.kitchenId ?? "",
          activeOrders: item.activeOrders ?? "{}",
          peopleActive: item.peopleActive ?? 0,
          protocols: item.protocols ?? "",
          kitchenSetup: item.kitchenSetup ?? [],
        },
        universalId: `${item.kitchenId}-${index.toString()}`,
        kitchenId: item.kitchenId,
        brandId: null,
        brandName: null,
        kitchenName: item.name,
        city: item.city,
        country: item.country,
        storeId: null,
        type: item.type,
      };

      kitchensStateItems.push(schemaTemp);
    });

    kitchensStateItems.forEach((store) => {
      if (!store) return;
      if (!store.kitchenId) return;

      const kitchenName = store?.kitchenName
        ?.toLowerCase()
        .replace("cocina ", "")
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      if (kitchenName?.includes("prueba")) return;

      if (!groupBrandsByKitchen[store?.kitchenId]) {
        groupBrandsByKitchen[store.kitchenId] = [];
      }

      let _protocols = [];
      if (store?.kitchen?.protocols) {
        _protocols = JSON.parse(store?.kitchen?.protocols);
      }

      let _activeOrders = null;
      if (store.kitchen && store.kitchen.activeOrders) {
        try {
          _activeOrders = JSON.parse(store.kitchen.activeOrders);
        } catch {}
      }

      groupBrandsByKitchen[store.kitchenId].push({
        id: store.universalId,
        country: store.country ?? "Not found",
        city: store.city ?? null,
        brandId: store.brandId ?? "Not found",
        brandName: store.brandName ?? "Not found",
        kitchenId: store.kitchenId ?? null,
        peopleActive: store.kitchen ? store.kitchen.peopleActive ?? 0 : 0,
        kitchenName: kitchenName ?? "Not found",
        kitchenActiveOrders: _activeOrders,
        type: store.type ?? null,
        protocols: _protocols,
        kitchenSetup: store.kitchen?.kitchenSetup ?? [],
        platforms: {
          rappi: getStoreState(store.rappiId, store.rappiState),
          ifood: getStoreState(store.ifoodId, store.ifoodState),
          uber: getStoreState(store.uberId, store.uberState),
          didi: getStoreState(store.didiId, store.didiState),
          pedidosya: getStoreState(store.pedidosyaId, store.pedidosyaState),
        },
      });
    });

    const kitchensState: Array<Kitchen> = Object.keys(groupBrandsByKitchen).map(
      (kitchenId: string) => ({
        id: kitchenId,
        name: groupBrandsByKitchen[kitchenId][0].kitchenName,
        city: groupBrandsByKitchen[kitchenId][0].city,
        country: groupBrandsByKitchen[kitchenId][0].country,
        activeOrders: groupBrandsByKitchen[kitchenId][0].kitchenActiveOrders,
        protocols: groupBrandsByKitchen[kitchenId][0].protocols,
        kitchenSetup: groupBrandsByKitchen[kitchenId][0].kitchenSetup,
        peopleActive: groupBrandsByKitchen[kitchenId][0].peopleActive,
        type: groupBrandsByKitchen[kitchenId][0].type,
        brands: groupBrandsByKitchen[kitchenId].map((store) => ({
          id: store.brandId ?? null,
          name: store.brandName,
          state: {
            [Platform.RAPPI]: getPlaformStoreState(store.platforms.rappi),
            [Platform.IFOOD]: getPlaformStoreState(store.platforms.ifood),
            [Platform.UBER]: getPlaformStoreState(store.platforms.uber),
            [Platform.DIDI]: getPlaformStoreState(store.platforms.didi),
            [Platform.PEDIDOSYA]: getPlaformStoreState(
              store.platforms.pedidosya
            ),
          },
        })),
        countAtention: 0,
      })
    );

    return kitchensState;
  }
}

export default AmplifyKitchenGateway;
