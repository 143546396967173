import { PolygonMap } from "../../components/PolygonMap";
import { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { getKitchensStateMaps } from "../../../store/slices/mapsStateSlice/mapsStateThunk";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import FilterMaps, { FilterMapParameters } from "./filterMap";
import { AsyncState } from "../../../store/types";
import { useLocation } from "react-router-dom";
import { setDataMaps } from "../../../store/slices/mapsStateSlice/mapsStateSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setTitle } from "../../../store/slices/screen.slice";

const MapsPage = () => {
  const dispatch = useAppDispatch();
  const { scope } = useSelector((state: RootState) => state.commonState);
  const [defaultParameters, setDefaultParameters] =
    useState<FilterMapParameters>();
  const coverage = useAppSelector((state) => state.mapsState.coverage);
  const location = useLocation();
  const { baseData, status } = useAppSelector(
    (state) => state.mapsState.kitchenState
  );
  const baseDataSummary = useAppSelector(
    (state) => state.storeState.kitchenState.baseData
  );
  const baseDataOrder = useAppSelector(
    (state) => state.orderState.kitchenState.baseData
  );

  const loadData = () => {
    if (baseDataSummary && baseDataSummary.length > 0) {
      dispatch(setDataMaps(baseDataSummary));
    } else if (baseDataOrder && baseDataOrder.length > 0) {
      dispatch(setDataMaps(baseDataOrder));
    } else {
      dispatch(getKitchensStateMaps(scope));
    }
  };

  useEffect(() => {
    if (baseData && baseData.length > 0) {
      setDefaultParameters(location.state as FilterMapParameters);
    }
  }, [baseData]);
  useEffect(() => {
    loadData();
    dispatch(setTitle("Mapas"));
  }, []);

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          position: "absolute",
          top: "60px",
          left: "90px",
          background: "white",
          width: "352px",
          zIndex: 3,
        }}
      >
        {status === AsyncState.SUCCESS ? (
          <FilterMaps
            defaultParameters={defaultParameters}
            baseData={baseData}
          />
        ) : (
          <Skeleton variant="rectangular" width={352} height={250} />
        )}
      </Box>
      {coverage.length > 0 &&
        coverage[0] &&
        coverage[0].coordinates != null &&
        coverage[0].coordinates.length > 0 && <PolygonMap />}
    </Box>
  );
};

export default MapsPage;
