import { Kitchen } from "domain/entities/Kitchen.enitity";
import { Platform, StoreCampaing } from "domain/entities/Store.entity";
import { AsyncState } from "store/types";

export enum StoreStateDrawers {
  COLUMN_VISIBILITY_DRAWER = "storeStateColumnVisibilityDrawer",
  STATE_DETAILS_DRAWER = "storeStateDetailsDrawer",
  COVERAGE_DETAILS_DRAWER = "storeCoverageDetailsDrawer",
  DISCOUNTS_DETAIL_DRAWER = "storeDiscountDetailDrawer",
}

export enum StoreStateDataTableColumns {
  RAPPI_DISCOUNT_STATE = "rappiDiscounts",
  RAPPI_STORE_STATE = "rappiId",
  IFOOD_STORE_STATE = "ifoodId",
  UBER_STORE_STATE = "uberId",
  DIDI_STORE_STATE = "didiId",
  PEDIDOSYA_STORE_STATE = "pedidosyaId",
  RAPPI_COVERAGE = "rappiIdCoverage",
  UBER_COVERAGE = "uberIdCoverage",
  IFOOD_COVERAGE = "ifoodIdCoverage",
}

export enum OrdersDataTableColumns {
  ACTIVE_ORDERS = "activeOrders",
  IN_STATION = "inStation",
  TO_PACKING = "toPacking",
  TODELIVER = "toDeliver",
  LATE_ORDER_SRT = "lateOrdersRT",
  LATE_ORDERS = "lateOrders",
  STAFF_IN_KITCHEN = "staffInKitchen",
}

export type StoreStateFilters = {
  selectedCountry: string | null;
  selectedCity: string | null;
  selectedKitchen: string | null;
};

export type ColumnVisibilityDrawerData = {
  orders: {
    [columnName in OrdersDataTableColumns]: boolean;
  };
  storeState: {
    [columnName in StoreStateDataTableColumns]: boolean;
  };
};

export type StoreStateDrawerData = {
  kitchen: Kitchen | null;
  platform: Platform | null;
};

export interface StoreStateState {
  drawers: {
    [StoreStateDrawers.COLUMN_VISIBILITY_DRAWER]: {
      open: boolean;
      data: ColumnVisibilityDrawerData;
    };

    [StoreStateDrawers.STATE_DETAILS_DRAWER]: {
      open: boolean;
      data: StoreStateDrawerData;
    };

    [StoreStateDrawers.COVERAGE_DETAILS_DRAWER]: {
      open: boolean;
      data: StoreStateDrawerData;
    };
    [StoreStateDrawers.DISCOUNTS_DETAIL_DRAWER]: {
      open: boolean;
      data: StoreStateDrawerData;
    };
  };
  kitchenState: {
    status: AsyncState;
    baseData: Kitchen[] | null;
    filteredData: Kitchen[] | null;
  };
  kitchenDiscount: {
    status: AsyncState;
    baseData: StoreCampaing[] | null;
    filteredData: StoreCampaing[] | null;
  };
}
