import React from "react";

interface BIconProps {
  color?: string;
  width?: string;
}

const BIcon = (props: BIconProps): React.ReactElement => {
  const { color, width } = props;
  return (
    <svg
      width={!width ? "25" : width}
      height="32"
      viewBox="0 0 25 32"
      fill={!color ? "#01579B" : color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.0055 17.956H4.78572L4.74176 13.4286H11.6429C12.859 13.4286 13.8553 13.2747 14.6319 12.967C15.4084 12.6447 15.9872 12.1832 16.3681 11.5824C16.7637 10.967 16.9615 10.2198 16.9615 9.34066C16.9615 8.34432 16.7711 7.53846 16.3901 6.92308C16.0238 6.30769 15.4451 5.86081 14.6539 5.58242C13.8773 5.30403 12.8736 5.16484 11.6429 5.16484H7.07143V32H0.478027V0H11.6429C13.5037 0 15.1667 0.175824 16.6319 0.527473C18.1117 0.879121 19.3645 1.41392 20.3901 2.13187C21.4158 2.84982 22.1996 3.75824 22.7418 4.85714C23.2839 5.94139 23.555 7.23077 23.555 8.72528C23.555 10.044 23.2546 11.2601 22.6539 12.3736C22.0678 13.4872 21.1374 14.3956 19.8626 15.0989C18.6026 15.8022 16.9542 16.1905 14.9176 16.2637L13.0055 17.956ZM12.7198 32H2.98352L5.55495 26.8571H12.7198C13.8773 26.8571 14.8223 26.674 15.555 26.3077C16.2876 25.9267 16.8297 25.4139 17.1813 24.7692C17.533 24.1245 17.7088 23.3846 17.7088 22.5495C17.7088 21.6117 17.5476 20.7985 17.2253 20.1099C16.9176 19.4212 16.4194 18.8938 15.7308 18.5275C15.0421 18.1465 14.1337 17.956 13.0055 17.956H6.65385L6.69781 13.4286H14.6099L16.1264 15.2088C18.0751 15.1795 19.6429 15.5238 20.8297 16.2418C22.0311 16.9451 22.9029 17.8608 23.4451 18.989C24.0018 20.1172 24.2802 21.326 24.2802 22.6154C24.2802 24.6667 23.8333 26.3956 22.9396 27.8022C22.0458 29.1941 20.7344 30.2418 19.0055 30.9451C17.2912 31.6484 15.196 32 12.7198 32Z" />
    </svg>
  );
};

export default BIcon;
