import Kitchen from "domain/entities/Kitchen.enitity";

export const mappedPeopleActiveOrder = (
  data: Array<Kitchen>,
  kitchenIndex: number,
  event: any
): Kitchen => {
  return { ...data[kitchenIndex], peopleActive: event.peopleActive };
};

export const mappedActiveOrders = (
  data: Array<Kitchen>,
  kitchenIndex: number,
  event: any
): Kitchen => {
  const parse = event.activeOrders ? JSON.parse(event.activeOrders) : null;
  return { ...data[kitchenIndex], activeOrders: parse };
};
