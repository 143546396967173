import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import Kitchen from "domain/entities/Kitchen.enitity";
import { Platform } from "domain/entities/Store.entity";
import { AsyncState, EventUpdateBase } from "store/types";
import { getKitchensState, getStoresWithCampaings } from "./storeStatethunks";
import { mappedBrandState } from "./helpers";

import {
  OrdersDataTableColumns,
  StoreStateDataTableColumns,
  StoreStateDrawers,
  StoreStateState,
} from "./types";
import OutOfTimeProcess from "../../../domain/useCases/kitchen/OutOfTime.process";
import { getStoreIdsByPlatform } from "../../../presentation/utils/store";

const initialState: StoreStateState = {
  drawers: {
    [StoreStateDrawers.COLUMN_VISIBILITY_DRAWER]: {
      open: false,
      data: {
        storeState: {
          rappiDiscounts: true,
          rappiId: true,
          ifoodId: true,
          uberId: true,
          didiId: true,
          pedidosyaId: true,
          rappiIdCoverage: true,
          uberIdCoverage: true,
          ifoodIdCoverage: true,
        },
        orders: {
          activeOrders: true,
          inStation: true,
          toPacking: true,
          toDeliver: true,
          lateOrdersRT: true,
          lateOrders: true,
          staffInKitchen: true,
        },
      },
    },

    [StoreStateDrawers.STATE_DETAILS_DRAWER]: {
      open: false,
      data: {
        kitchen: null,
        platform: null,
      },
    },

    [StoreStateDrawers.COVERAGE_DETAILS_DRAWER]: {
      open: false,
      data: {
        kitchen: null,
        platform: null,
      },
    },
    [StoreStateDrawers.DISCOUNTS_DETAIL_DRAWER]: {
      open: false,
      data: {
        kitchen: null,
        platform: null,
      },
    },
  },
  kitchenState: {
    baseData: null,
    filteredData: null,
    status: AsyncState.IDLE,
  },
  kitchenDiscount: {
    baseData: null,
    filteredData: null,
    status: AsyncState.IDLE,
  },
};

const dataTableSlice = createSlice({
  name: "store-state",
  initialState,
  reducers: {
    clearStores: (state, action) => {
      state.kitchenState.baseData = null;
      state.kitchenState.filteredData = null;
    },
    setDrawerOpen: (
      state,
      action: PayloadAction<{ drawerName: StoreStateDrawers; isOpen: boolean }>
    ) => {
      const { drawerName, isOpen } = action.payload;

      state.drawers[drawerName].open = isOpen;
    },

    toggleStoreStateDataTableColumnVisibility: (
      state,
      action: PayloadAction<{
        columnName: StoreStateDataTableColumns;
        isVisible: boolean;
      }>
    ) => {
      const drawerName = StoreStateDrawers.COLUMN_VISIBILITY_DRAWER;
      const { columnName, isVisible } = action.payload;

      state.drawers[drawerName].data.storeState[columnName] = isVisible;
    },

    toggleOrdersDataTableColumnVisibility: (
      state,
      action: PayloadAction<{
        columnName: OrdersDataTableColumns;
        isVisible: boolean;
      }>
    ) => {
      const drawerName = StoreStateDrawers.COLUMN_VISIBILITY_DRAWER;
      const { columnName, isVisible } = action.payload;

      state.drawers[drawerName].data.orders[columnName] = isVisible;
    },

    setAllOrdersColumnVisibility: (state, action) => {
      const drawerName = StoreStateDrawers.COLUMN_VISIBILITY_DRAWER;

      state.drawers[drawerName].data.orders = action.payload;
    },

    setAllStoreStateColumnVisibility: (state, action) => {
      const drawerName = StoreStateDrawers.COLUMN_VISIBILITY_DRAWER;

      state.drawers[drawerName].data.storeState = action.payload;
    },

    setDrawerKitchenData: (
      state,
      action: PayloadAction<{
        drawerName: StoreStateDrawers;
        kitchen: Kitchen;
        platform: Platform;
      }>
    ) => {
      const { drawerName, kitchen, platform } = action.payload;

      state.drawers[drawerName].data = {
        kitchen,
        platform,
      };
    },

    setDrawerDiscountData: (
      state,
      action: PayloadAction<{
        drawerName: StoreStateDrawers;
        kitchen: Kitchen;
        platform: Platform;
      }>
    ) => {
      const { drawerName, kitchen, platform } = action.payload;

      state.drawers[drawerName].data = {
        kitchen,
        platform,
      };

      if (state.kitchenDiscount.baseData) {
        const storeIds = getStoreIdsByPlatform(kitchen, Platform.RAPPI);
        state.kitchenDiscount.filteredData =
          state.kitchenDiscount.baseData.filter(
            (c) => c.kitchenId && storeIds.some((id) => id === c.universalId)
          );
      }
    },

    setFilteredData: (state, action) => {
      state.kitchenState.filteredData = action.payload;
    },

    updateKitchenStateBaseData: (state, action) => {
      const event: EventUpdateBase = action.payload;
      const processKitchenOutTime = new OutOfTimeProcess();

      const baseData: Array<Kitchen> | null = current(
        state.kitchenState.baseData
      );

      const filteredData: Array<Kitchen> | null = current(
        state.kitchenState.filteredData
      );

      if (
        state.kitchenState.filteredData === null ||
        filteredData === null ||
        baseData === null
      ) {
        return;
      }

      const kitchenIndex = filteredData?.findIndex((kitchen) =>
        kitchen.brands.some(
          (brand) => event.universalId === brand.state[event.platform]?.storeId
        )
      );
      if (kitchenIndex > -1) {
        const kitchenBrandIndex = filteredData[kitchenIndex].brands.findIndex(
          (brand) => event.universalId === brand.state[event.platform]?.storeId
        );

        let brands = mappedBrandState(
          filteredData,
          kitchenIndex,
          kitchenBrandIndex,
          event
        );
        let kitchen = { ...filteredData[kitchenIndex], brands: brands };

        const result = processKitchenOutTime.execute([kitchen]);
        if (result.length > 0) {
          kitchen = result[0];
        }

        state.kitchenState.filteredData = filteredData.map(
          (kitchenItemMap, index) => {
            if (index === kitchenIndex) return kitchen;
            return kitchenItemMap;
          }
        );
      }

      //update Base
      if (state.kitchenState.baseData === null) return;
      const kitchenIndexBase = baseData?.findIndex((kitchen) =>
        kitchen.brands.some(
          (brand) => event.universalId === brand.state[event.platform]?.storeId
        )
      );
      if (kitchenIndexBase > -1) {
        const kitchenBrandIndexBase = baseData[
          kitchenIndexBase
        ].brands.findIndex(
          (brand) => event.universalId === brand.state[event.platform]?.storeId
        );

        let brandsBase = mappedBrandState(
          baseData,
          kitchenIndexBase,
          kitchenBrandIndexBase,
          event
        );

        let kitchenBase = { ...baseData[kitchenIndexBase], brands: brandsBase };

        const resultBase = processKitchenOutTime.execute([kitchenBase]);
        if (resultBase.length > 0) {
          kitchenBase = resultBase[0];
        }

        state.kitchenState.baseData = baseData.map((kitchenItemMap, index) => {
          if (index === kitchenIndexBase) return kitchenBase;
          return kitchenItemMap;
        });

        //UPDATE DRAWER OPENED
        const details = current(
          state.drawers[StoreStateDrawers.STATE_DETAILS_DRAWER].data
        );
        const existInDrawer = details.kitchen?.brands.some(
          (brand) => brand.state[event.platform]?.storeId === event.universalId
        );

        if (existInDrawer) {
          state.drawers[StoreStateDrawers.STATE_DETAILS_DRAWER].data = {
            ...state.drawers[StoreStateDrawers.STATE_DETAILS_DRAWER].data,
            kitchen: kitchenBase,
          };
        }

        //UPDATE DRAWER OPENED
        const details_coverage = current(
          state.drawers[StoreStateDrawers.COVERAGE_DETAILS_DRAWER].data
        );
        const existInDrawerCoverage = details_coverage.kitchen?.brands.some(
          (brand) => brand.state[event.platform]?.storeId === event.universalId
        );

        if (existInDrawerCoverage) {
          state.drawers[StoreStateDrawers.COVERAGE_DETAILS_DRAWER].data = {
            ...state.drawers[StoreStateDrawers.COVERAGE_DETAILS_DRAWER].data,
            kitchen: kitchenBase,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKitchensState.pending, (state, action) => {
      state.kitchenState.status = AsyncState.LOADING;
    });
    builder.addCase(getKitchensState.fulfilled, (state, action) => {
      state.kitchenState.status = AsyncState.SUCCESS;
      state.kitchenState.baseData = action.payload;
      state.kitchenState.filteredData = action.payload;
    });
    builder.addCase(getKitchensState.rejected, (state, action) => {
      state.kitchenState.status = AsyncState.ERROR;
    });
    builder.addCase(getStoresWithCampaings.pending, (state, action) => {
      state.kitchenDiscount.status = AsyncState.LOADING;
    });
    builder.addCase(getStoresWithCampaings.fulfilled, (state, action) => {
      state.kitchenDiscount.status = AsyncState.SUCCESS;
      state.kitchenDiscount.baseData = action.payload;
    });
    builder.addCase(getStoresWithCampaings.rejected, (state, action) => {
      state.kitchenState.status = AsyncState.ERROR;
    });
  },
});

export const {
  clearStores,
  setDrawerOpen,
  toggleStoreStateDataTableColumnVisibility,
  toggleOrdersDataTableColumnVisibility,
  setDrawerKitchenData,
  setDrawerDiscountData,
  setAllOrdersColumnVisibility,
  setAllStoreStateColumnVisibility,
  setFilteredData,
  updateKitchenStateBaseData,
} = dataTableSlice.actions;
export default dataTableSlice.reducer;
