import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const DAY_OPEN_NPS = 15;
const useNPSEmbed = (url: string) => {
  const navigate = useNavigate();
  const [statusDialogNPS, setOpen] = useState(false);
  const [nps, setNps] = useLocalStorage("monitor.nps", {
    date: new Date(),
    redirect: url,
    done: false,
  });
  const openDialogNPS = () => {
    const saveDate = new Date(nps.date);
    const currentDate = new Date();
    if (nps.done === false && currentDate.getDate() === DAY_OPEN_NPS) {
      setOpen(true);
    } else if (
      saveDate.getDate() !== currentDate.getDate() &&
      currentDate.getDate() === DAY_OPEN_NPS
    ) {
      setNps({
        ...nps,
        done: false,
      });
      setOpen(true);
    }
  };
  const closeDialogNPS = (): void => {
    setOpen(false);
  };

  const successCallback = () => {
    setNps({
      redirect: nps.redirect,
      date: new Date(),
      done: true,
    });
  };

  useEffect(() => {
    if (nps.done) {
      navigate(nps.redirect);
    }
  }, [nps]);

  useEffect(() => {
    if (url.length > 0) {
      setNps({ ...nps, redirect: url });
    }
  }, []);
  return { statusDialogNPS, openDialogNPS, closeDialogNPS, successCallback };
};

export default useNPSEmbed;
