import React, { FunctionComponent } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PaperTable from "../../../../components/Datatable/PaperTable";
import { Supply } from "../../../../../domain/entities/Supplies.entity";

interface SuppliesOffDetailsContentProps {
  supplies: Supply[];
  rows: any[];
}

const SuppliesOffDetailsContent: FunctionComponent<
  SuppliesOffDetailsContentProps
> = ({ supplies, rows }) => {
  const columns: GridColDef[] = [
    { field: "sku", headerName: "SKU", width: 52, editable: false },
    { field: "supply", headerName: "Insumo", width: 105, editable: false },
    { field: "type", headerName: "Tipo", editable: false },
    {
      field: "inventory",
      headerName: "Inventario",
      width: 105,
      editable: false,
    },
  ];
  return (
    <Box sx={{ padding: 2 }}>
      <PaperTable height="240px">
        <DataGrid
          sx={{ height: "auto" }}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowHeight={48}
        />
      </PaperTable>
    </Box>
  );
};

export default SuppliesOffDetailsContent;
