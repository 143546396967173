import { createSlice } from "@reduxjs/toolkit";
import { getKitchensStateMaps } from "./mapsStateThunk";
import { AsyncState } from "../../types";
import { MapsState } from "./type";

const initialState: MapsState = {
  kitchenState: {
    status: AsyncState.IDLE,
    baseData: null,
  },
  coverage: [
    {
      area: 0,
      coordinates: [],
      coordinatesEvaluated: 0,
      kitchenCoordinate: {
        lat: 0,
        lng: 0,
      },
      lastUpdate: "",
      unit: "",
      version: 0,
    },
  ],
  version: 0,
};
const mapsStateSlice = createSlice({
  name: "maps-state-slice",
  initialState,
  reducers: {
    clear: (state, action) => {},
    setCoverage: (state, action) => {
      state.coverage = action.payload;
    },
    setDataMaps: (state, action) => {
      state.kitchenState.baseData = action.payload;
      state.kitchenState.status = AsyncState.SUCCESS;
    },
    setVersion: (state, action) => {
      state.version++;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKitchensStateMaps.pending, (state, action) => {
      state.kitchenState.status = AsyncState.LOADING;
    });
    builder.addCase(getKitchensStateMaps.rejected, (state, action) => {
      state.kitchenState.status = AsyncState.ERROR;
    });
    builder.addCase(getKitchensStateMaps.fulfilled, (state, action) => {
      state.kitchenState.status = AsyncState.SUCCESS;
      state.kitchenState.baseData = action.payload;
    });
  },
});

export const { clear, setCoverage, setVersion, setDataMaps } =
  mapsStateSlice.actions;
export default mapsStateSlice.reducer;
