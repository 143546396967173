import { FunctionComponent, ReactElement } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  children: ReactElement | Array<ReactElement>;
}

const PageHeader: FunctionComponent<PageHeaderProps> = (props) => {
  const { title, subtitle, children } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 2, md: 0 },
        pt: 2,
      }}
    >
      <Box
        sx={{
          width: { xs: 1, md: 2 / 4, lg: 1 / 4 },
        }}
      >
        <Typography variant="h1" sx={{ fontSize: { xs: 42, xl: 40 } }}>
          {title}
        </Typography>

        {subtitle !== undefined && (
          <Typography
            variant="body1"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        sx={{ width: { xs: 1, lg: 3 / 4 } }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageHeader;
