import { ResourceLanguage } from "i18next";
import { AlarmsLocale } from "../../../domain/interfaces/i18n/alarms.interface";
import { DashboardUptimeLocale } from "../../../domain/interfaces/i18n/dashboards.interface";
import { FilterLocale } from "../../../domain/interfaces/i18n/filters.interface";
import { OrderPageLocale } from "../../../domain/interfaces/i18n/order.interface";
import { SummaryPageLocale } from "../../../domain/interfaces/i18n/summary.interface";
import { SuppliesSummaryPageLocale } from "../../../domain/interfaces/i18n/suppliesSummary.interface";

const esLocale: ResourceLanguage = {
  [SummaryPageLocale.TITLE]: "Resumen Tiendas",
  [SummaryPageLocale.DESCRIPTION]:
    "Personaliza los datos que deseas visualizar en la tabla",
  [SummaryPageLocale.ATENTION]: "Atención",
  [SummaryPageLocale.OK]: "Ok",

  [SummaryPageLocale.KITCHEN]: "Cocina",
  [SummaryPageLocale.BRANDS]: "Marcas",
  [SummaryPageLocale.POLYGONS]: "Polígonos",
  [SummaryPageLocale.NO_BRANDS_AVAILABLE]: "No hay marcas en esta plataforma",
  [SummaryPageLocale.NO_BRANDS_COVERAGE]: "No hay informarción de cobertura",
  [SummaryPageLocale.WITHOUT_INFORMATION]: "Sin información",

  [SummaryPageLocale.BUTTON_EDIT_COLUMNS]: "Editar columnas",
  [FilterLocale.BUTTON]: "Filtros",
  [FilterLocale.TITLE]: "Filtros",
  [FilterLocale.ALL]: "Todos",
  [FilterLocale.SEARCH_TEXT]: "Busca por ciudad o cocina",
  [FilterLocale.ALL_CITIES]: "Todas las ciudades",
  [FilterLocale.ALL_KITCHENS]: "Todas las cocinas",
  [FilterLocale.COUNTRY]: "Paises",
  [FilterLocale.CITY]: "Ciudades",
  [FilterLocale.KITCHEN]: "Cocina",

  [SummaryPageLocale.DRAWER_COLUMN_TITLE]: "Editar columnas",
  [SummaryPageLocale.DRAWER_COLUMN_DESCRIPTION]:
    "Oculta o agrega columnas en la tabla",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_TITLE]: "Configuración de columnas",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_DESCRIPTION]:
    "Escoge las columnas que deseas ocultar",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_IN]: "Marcas en",
  [SummaryPageLocale.DRAWER_COLUMN_COVERAGE_IN]: "Cobertura",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_COVERAGE]:
    "Marcas prendidas y apagadas",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_POLYGON]:
    "Cobertura polígonos de las marcas",

  [SummaryPageLocale.SHOPS]: "Tiendas",
  [SummaryPageLocale.BRAND]: "Marca",
  [SummaryPageLocale.LAST_UPDATE]: "Última actualización",
  [SummaryPageLocale.IN_SCHEDULE]: "Dentro del horario",
  [SummaryPageLocale.OUT_SCHEDULE]: "Fuera del horario",
  [SummaryPageLocale.OFF]: "Apagadas",
  [SummaryPageLocale.ON]: "Prendidas",
  [SummaryPageLocale.SHOULD_OFF]: "Debería estar apagadas",
  [SummaryPageLocale.SHOULD_OFF_BY_SCHEDULE]:
    "Debería estar apagadas por horario",
  [SummaryPageLocale.SHOULD_ON]: "Debería estar prendidas",
  [SummaryPageLocale.SHOULD_ON_BY_SCHEDULE]:
    "Debería estar prendidas por horario",
  [SummaryPageLocale.SCHEDULE_DEFINED]: "Horario definido:",
  [SummaryPageLocale.MOTIVE]: "Motivo:",
  [SummaryPageLocale.AGO]: "Hace",
  [SummaryPageLocale.DAYS]: "dias",
  [SummaryPageLocale.NOW]: "Ahora",
  [SummaryPageLocale.MINUTES]: "min",

  [OrderPageLocale.TITLE]: "Órdenes Activas",
  [OrderPageLocale.DESCRIPTION]:
    "Conoce en vivo los datos de los pedidos en cocina",
  [OrderPageLocale.COOKING]: "Cocinando",
  [OrderPageLocale.PACKING]: "Empacando",
  [OrderPageLocale.TO_DELIVER]: "Por entregar",
  [OrderPageLocale.ORDER_RT]: "Con repartidor",
  [OrderPageLocale.ORDER_WITHOUT_RT]: "Sin repartidor",
  [OrderPageLocale.ORDER_LATER]: "Órdenes tarde",
  [OrderPageLocale.PEOPLE_IN_KITCHEN_RESUMEN]: "Personas en cocina",
  [OrderPageLocale.PEOPLE_IN_KITCHEN]: "Personal en cocina vs realizado",

  [OrderPageLocale.KITCHEN]: "Cocina",
  [OrderPageLocale.ORDER_ACTIVE]: "Órdenes activas",
  [OrderPageLocale.LAST_UPDATE]: "Última actualización",
  [OrderPageLocale.IN_STATION]: "En Estación",
  [OrderPageLocale.IN_QUEUE]: "En cola y cocinando",
  [OrderPageLocale.OK]: "Ok",
  [OrderPageLocale.ATENTION]: "Atención",

  [OrderPageLocale.PLATFORM]: "PLATAFORMA",
  [OrderPageLocale.BRAND]: "MARCA",
  [OrderPageLocale.TOTAL_ORDERS]: "TOTAL ORDENES",
  [OrderPageLocale.MAIN_PLATE]: "PLATOS FUERTES",

  [OrderPageLocale.DRAWER_ORDERS_TITLE]: "Ordenes Activas",
  [OrderPageLocale.DRAWER_ORDERS_DESCRIPTION]:
    "Visualiza los pedidos en estación",

  [OrderPageLocale.DRAWER_DELIVER_TITLE]: "Por Entregar",
  [OrderPageLocale.DRAWER_DELIVER_DESCRIPTION]:
    "Visualiza los pedidos que están listos y aún no se han entregado",

  [OrderPageLocale.PERSONAL]: "PERSONAL",
  [OrderPageLocale.SCHEDULE_IN]: "INGRESÓ A LAS",
  [OrderPageLocale.DATE_IN]: "Fecha de ingreso",
  [OrderPageLocale.HOURS_IN_KITCHEN]: "Horas en cocina",
  [OrderPageLocale.DRAWER_PEOPLE_TITLE]: "Personal en cocina",
  [OrderPageLocale.DRAWER_PEOPLE_DESCRIPTION]:
    "Visualiza las personas activas en el turno",

  [OrderPageLocale.PEOPLE_CARD_TITLE]: "Personal en cocina",
  [OrderPageLocale.PEOPLE_CARD_DESCRIPTION]:
    "Cocineros o jefes de cocina con turnos activos",
  [OrderPageLocale.PEOPLE_CARD_CORP_TITLE]: "Personal coorporativo",
  [OrderPageLocale.PEOPLE_CARD_CORP_DESCRIPTION]:
    "Quality, Ops, etc con turno activo en cocina",

  [AlarmsLocale.TITLE]: "Alarmas",
  [AlarmsLocale.SUBTITLE]:
    "Suscribete a las alarmas que desees recibir de los eventos en las cocinas para estar al día",
  [AlarmsLocale.FORM_TITLE]: "Crear una nueva alarma",
  [AlarmsLocale.FORM_EVENT]: "Evento*",
  [AlarmsLocale.FORM_EVENT_LABEL]:
    "Evento por el que recibirás la notificación",
  [AlarmsLocale.FORM_EVENT_PLACEHOLDER]: "Escoge el evento",
  [AlarmsLocale.EVENT_ON]: "Prendidos y apagados",
  [AlarmsLocale.EVENT_POLYGONS]: "Polígonos",
  [AlarmsLocale.FORM_CHANNEL]: "Canal*",
  [AlarmsLocale.FORM_CHANNEL_LABEL]:
    "Solo disponible Whatsapp, pronto podrás escoger otros canales",
  [AlarmsLocale.FORM_COUNTRY]: "País",
  [AlarmsLocale.FORM_COUNTRY_LABEL]: "",
  [AlarmsLocale.FORM_COUNTRY_PLACEHOLDER]: "Escoge el país",
  [AlarmsLocale.FORM_CITY]: "Ciudad",
  [AlarmsLocale.FORM_CITY_LABEL]: "",
  [AlarmsLocale.FORM_CITY_PLACEHOLDER]: "Escoge la ciudad",
  [AlarmsLocale.FORM_BRAND]: "Marcas",
  [AlarmsLocale.FORM_BRAND_LABEL]: "",
  [AlarmsLocale.FORM_BRAND_PLACEHOLDER]: "Escoge la marca",
  [AlarmsLocale.FORM_PHONE_NUMBER]: "Número de teléfono*",
  [AlarmsLocale.FORM_PHONE_NUMBER_LABEL]:
    "Número al que te enviaremos la notificación",
  [AlarmsLocale.FORM_PHONE_NUMBER_PLACEHOLDER]: "Número de teléfono",
  [AlarmsLocale.FORM_SUBMIT]: "CREAR ALARMA",
  [AlarmsLocale.ALL]: "Todas",

  [AlarmsLocale.NEW_ALARM_TEXT]:
    "Ingresa en “Crea una alarma nueva” para que puedas comenzar a recibir alertas del estado de tus cocinas",
  [AlarmsLocale.NEW_ALARM_BUTTON]: "CREAR MI PRIMERA ALARMA",
  [AlarmsLocale.NEW_ALARM_SUCCESS]: "Alarma creada exitosamente",
  [AlarmsLocale.CONTINUE]: "Continuar",
  [AlarmsLocale.PHONE]: "Teléfono",
  [AlarmsLocale.ACTIONS]: "Acciones",
  [AlarmsLocale.PREV_EVENTS]: "Previamente creadas",
  [AlarmsLocale.ACTIVE]: "Activo",
  [AlarmsLocale.RECEIVED_ALARM_COPY1]: "Vas a recibir notificaciones en",
  [AlarmsLocale.RECEIVED_ALARM_COPY2]: "sobre",
  [AlarmsLocale.RECEIVED_ALARM_COPY3]: "de",
  [AlarmsLocale.RECEIVED_ALARM_COPY4]: "en",
  [AlarmsLocale.RECEIVED_ALARM_COPY5]: "al",

  [DashboardUptimeLocale.TITLE]: "Dashboard Uptime",
  [DashboardUptimeLocale.SUBTITLE]: "copy dasboard uptime..",

  [SuppliesSummaryPageLocale.TITLE]: "Resumen Tiendas",
  [SuppliesSummaryPageLocale.DESCRIPTION]:
    "Personaliza los datos que deseas visualizar en la tabla",
  [SuppliesSummaryPageLocale.TOTALOFF]: "Total apagados",
  [SuppliesSummaryPageLocale.TYPEAA]: "Tipo AA",
  [SuppliesSummaryPageLocale.TYPEA]: "Tipo A",
  [SuppliesSummaryPageLocale.TYPEB]: "Tipo B",
  [SuppliesSummaryPageLocale.TYPEC]: "Tipo C",
  [SuppliesSummaryPageLocale.KITCHEN]: "Cocina",
};

export default esLocale;
