import { Platform } from "./Store.entity";

export enum ItemCategory {
  PROTEIN = "PROTEINA",
  PACKAGE = "EMPAQUE",
  MAIN_COURSE = "PLATO FUERTE",
  BASE = "BASE",
  TOPPING = "TOPPING",
  BACKERY = "PANADERIA",
  SIDE_DISHES = "PORCIONES APARTE",
  DESSERTS = "POSTRES",
  DRINK = "BEBIDA",
  SAUCE = "SALSAS",
  STATION_SAUCE = "SALSAS ESTACION",
  CUTLERY = "CUBIERTOS",
  DONATION = "DONACION",
  UNCATEGORIZED = "SIN CATEGORIA",
}

export enum DomiciliaryStatus {
  NOT_ASSIGNED = "not_assigned", // No asignado según plataforma
  DOMICILIARY_ASSIGNED = "domiciliary_assigned", // En camino según plataforma
  DOMICILIARY_IN_STORE = "domiciliary_in_store", // En tienda según plataforma
  DOMICILIARY_CHECKED_IN = "domiciliary_checked_in", // Mensajero registrado
  DOMICILIARY_DID_TAKE = "domiciliary_did_take", // Entregado según plataforma
  NOT_AVAILABLE = "not_available",
  ASSIGNED = "assigned",
  IN_KITCHEN = "in_kitchen",
}
export enum OrderState {
  QUEUED = "queued",
  COOKING = "cooking",
  RETURNED_TO_STATION = "returned_to_station",
  PACKING = "packing",
  WAITING_FOR_DELIVERY = "waiting_for_delivery",
  HANDED_TO_DELIVERY = "handed_to_delivery",
  ARCHIVED = "archived",
  CANCELLED = "cancelled",
  CANCEL_NOTIFICATION = "cancel_notification",
}

export enum Shipping {
  PICKUP = "PickUp",
  DELIVERY = "Delivery",
}

export interface discountSchema {
  by: String;
  value: Number;
  promoType: String;
  promoId: { type: String; optional: true };
}

export interface priceSchema {
  gross: Number;
  net: { type: Number; optional: true };
  discounts: [discountSchema];
}
export interface coordinatesSchema {
  lat: Number;
  lng: Number;
}
export interface locationSchema {
  address: String;
  city: { type: String; optional: true };
  coordinates: { type: coordinatesSchema; optional: true };
  notes: { type: String; optional: true };
}
export interface domiciliarySchema {
  fullName: String;
  phone: { type: String; optional: true };
  pictureUrl: { type: String; optional: true };
}

export interface shippingSchema {
  method: String;
  by: { type: String; optional: true };
  price: { type: priceSchema; optional: true };
  location: { type: locationSchema; optional: true };
  domiciliary: { type: domiciliarySchema; optional: true };
}

export interface Order {
  _id: string;
  id: string;
  brand: {
    id: string;
  };
  shipping: shippingSchema;
  displayInfo: {
    domiciliaryStatus: DomiciliaryStatus;
  };
  external: {
    id: string;
  };
  lastUpdated: Date;
  platform: Platform;
  receivedAt: Date;
  state: OrderState;
}

export interface ItemBase {
  sku: string;
  units: number;
  name: string;
  category: ItemCategory;
  spoons?: string;
  spoonsColor?: string;
  portion?: string;
}

export interface QueryOrderActive {
  _id: string;
  brand: {
    id: string;
    name: string;
  };
  cart: Array<ItemBase>;
  cartExploited?: Array<any>;
  platform: string;
  station: string;
}

export interface OrderActiveGroupDetail {
  id: string;
  platform: string;
  brand: string;
  total: number;
  strong: number;
}
export interface OrderActiveGroupDrawer {
  totalOrders: number;
  station: string;
  details: OrderActiveGroupDetail[];
}
