export enum FilterLocale {
  BUTTON = "filter.button",
  TITLE = "filter.title",
  SEARCH_TEXT = "filter.search_text",
  ALL = "filter.all",
  ALL_CITIES = "filter.all_cities",
  ALL_KITCHENS = "filter.all_kitchens",
  COUNTRY = "filter.country",
  CITY = "filter.city",
  KITCHEN = "filter.kitchen",
}
