import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  AccordionSummary,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { RootState } from "../../../store";
import {
  deleteFilterSelectedByIndex,
  ICity,
  ICountry,
  IKitchenItem,
  ResultType,
  setCheckAllCity,
  setCheckAllCountry,
  setCheckAllKitchen,
  setCitiesAll,
  setCountriesAll,
  setFilterSelected,
  setKitchensAll,
  updateCheckedCity,
  updateCheckedKitchen,
} from "../../../store/slices/filterSlice";
import { getCityName } from "../../utils/city";
import { useTranslation } from "react-i18next";
import { FilterLocale } from "../../../domain/interfaces/i18n/filters.interface";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} disableGutters {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function FilterMultipleChoice() {
  const [panelState, setPanelState] = React.useState({
    city: false,
    country: false,
    kitchen: false,
  });
  const [country, setCountries] = React.useState<Array<ICountry>>();
  const [cities, setCities] = React.useState<Array<ICity>>();
  const [kitchens, setKitchens] = React.useState<Array<IKitchenItem>>();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    kitchenItems,
    kitchensAll,
    countriesAll,
    citiesAll,
    checkAllCity,
    checkAllCountry,
    checkAllKitchen,
    resultSearchSelected,
  } = useSelector((state: RootState) => state.filter);

  const handleCountryFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (country && country.length > 0) {
      if (event.target.checked) {
        let newFilterSelected = [...resultSearchSelected];
        newFilterSelected.push({
          type: ResultType.COUNTRY,
          name: country[index].name,
          checked: true,
          children: citiesAll
            .filter((c) => c.country === country[index].name)
            .map((item) => ({
              type: ResultType.CITY,
              name: item.name,
              checked: true,
              children: kitchensAll
                .filter((k) => k.city === item.name)
                .map((kitchen) => ({
                  type: ResultType.KITCHEN,
                  name: kitchen.name,
                  checked: true,
                  children: [],
                })),
            })),
        });
        dispatch(setFilterSelected(newFilterSelected));
      } else {
        let indexCountry = resultSearchSelected.findIndex(
          (r) => r.name === country[index].name && r.type === ResultType.COUNTRY
        );
        dispatch(deleteFilterSelectedByIndex(indexCountry));
      }
    }
  };

  const handleCityFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: ICity
  ) => {
    if (cities && cities.length > 0) {
      const indexCountry = resultSearchSelected.findIndex(
        (co) => co.name === item.country && co.type === ResultType.COUNTRY
      );
      if (indexCountry !== -1) {
        const indexCity = resultSearchSelected[indexCountry].children.findIndex(
          (c) => c.name === item.name && c.type === ResultType.CITY
        );
        if (indexCity !== -1) {
          dispatch(
            updateCheckedCity({
              indexCity,
              indexCountry,
              checked: event.target.checked,
            })
          );
        }
      }
    }
  };

  const handleKitchenFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IKitchenItem
  ) => {
    if (kitchens && kitchens.length > 0) {
      const indexCountry = resultSearchSelected.findIndex(
        (co) => co.name === item.country && co.type === ResultType.COUNTRY
      );
      if (indexCountry !== -1) {
        const indexCity = resultSearchSelected[indexCountry].children.findIndex(
          (c) => c.name === item.city && c.type === ResultType.CITY
        );
        if (indexCity !== -1) {
          const indexKitchen = resultSearchSelected[indexCountry].children[
            indexCity
          ].children.findIndex(
            (k) => k.name === item.name && k.type === ResultType.KITCHEN
          );
          dispatch(
            updateCheckedKitchen({
              indexCity,
              indexCountry,
              indexKitchen,
              checked: event.target.checked,
            })
          );
        }
      }
    }
  };

  const updateFilters = () => {
    // cities
    let countrySelected: ICountry[] = [];
    let citySelected: ICity[] = [];
    if (country && citiesAll) {
      let newCities: ICity[] = [...citiesAll];
      countrySelected = resultSearchSelected.filter(
        (c) => c.type === ResultType.COUNTRY
      );
      newCities = newCities.filter((c) =>
        countrySelected.some((co) => co.name === c.country)
      );

      setCities(newCities);

      if (cities && kitchensAll) {
        let newKitchens: IKitchenItem[] = [...kitchensAll];
        resultSearchSelected
          .filter(
            (co) =>
              co.type === ResultType.COUNTRY &&
              co.children.some((c) => c.checked && c.type === ResultType.CITY)
          )
          .forEach((co) => {
            co.children.forEach((c) => {
              if (c.checked && c.type === ResultType.CITY) {
                citySelected.push({ name: c.name, country: co.name });
              }
            });
          });
        newKitchens = newKitchens.filter((k) =>
          citySelected.some((c) => c.name === k.city && c.country === k.country)
        );
        setKitchens(newKitchens);
      }
    }
  };

  const createCollectionsMaster = () => {
    if (kitchenItems !== null && kitchenItems.length > 0) {
      const filteredCountries: Array<ICountry> = [];
      const filteredCities: Array<ICity> = [];
      const filteredKitchens: Array<IKitchenItem> = [];
      kitchenItems.forEach((kitchen: any) => {
        // countries
        if (!filteredCountries.some((c) => c.name === kitchen.country)) {
          filteredCountries.push({
            name: kitchen.country,
          });
        }
        // cities
        if (!filteredCities.some((c) => c.name === kitchen.city)) {
          filteredCities.push({
            name: kitchen.city,
            country: kitchen.country,
          });
        }
        //kitchens
        if (!filteredKitchens.includes(kitchen.name)) {
          filteredKitchens.push({
            country: kitchen.country,
            city: kitchen.city,
            name: kitchen.name,
          });
        }
      });

      setCountries(filteredCountries);

      setCities([]);
      setKitchens([]);

      dispatch(setCountriesAll(filteredCountries));
      dispatch(setCitiesAll(filteredCities));
      dispatch(setKitchensAll(filteredKitchens));
    }
  };

  const handleCheckAllCity = (checked: boolean) => {
    dispatch(setCheckAllCity(checked));
    resultSearchSelected
      .filter((co) => co.type === ResultType.COUNTRY)
      .forEach((co) => {
        co.children.forEach((c) => {
          dispatch(
            updateCheckedCity({
              indexCity: co.children.indexOf(c),
              indexCountry: resultSearchSelected.indexOf(co),
              checked: checked,
            })
          );
        });
      });
  };

  const handleCheckAllKitchen = (checked: boolean) => {
    dispatch(setCheckAllKitchen(checked));
    resultSearchSelected
      .filter((co) => co.type === ResultType.COUNTRY)
      .forEach((co) => {
        co.children.forEach((c) => {
          c.children.forEach((k) => {
            dispatch(
              updateCheckedKitchen({
                indexCity: co.children.indexOf(c),
                indexCountry: resultSearchSelected.indexOf(co),
                indexKitchen: c.children.indexOf(k),
                checked: checked,
              })
            );
          });
        });
      });
  };

  const updateCheckAll = () => {
    //checked or unchecked all kitchens
    let totalUncheckedKitchen = 0;
    resultSearchSelected
      .filter((co) => co.type === ResultType.COUNTRY)
      .forEach((co) => {
        co.children.forEach((c) => {
          c.children.forEach((k) => {
            if (c.checked && !k.checked) totalUncheckedKitchen++;
          });
        });
      });
    dispatch(setCheckAllKitchen(totalUncheckedKitchen === 0));

    //checked or unchecked all cities
    let totalUncheckedCity = 0;
    resultSearchSelected
      .filter((co) => co.type === ResultType.COUNTRY)
      .forEach((co) => {
        co.children.forEach((c) => {
          if (!c.checked) totalUncheckedCity++;
        });
      });
    dispatch(setCheckAllCity(totalUncheckedCity === 0));
  };
  useEffect(() => {
    updateFilters();
    updateCheckAll();
    localStorage.setItem(
      "monitor.filters",
      JSON.stringify(resultSearchSelected)
    );
  }, [resultSearchSelected]);

  useEffect(() => {
    updateFilters();
  }, [country, countriesAll, citiesAll, kitchensAll]);

  useEffect(() => {
    createCollectionsMaster();
  }, [kitchenItems]);

  useEffect(() => {
    createCollectionsMaster();
  }, []);

  const handleChangePanel = (name: string) => {
    setPanelState((prevState: any) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  return (
    <>
      <Accordion
        expanded={panelState.country}
        onChange={() => handleChangePanel("country")}
        elevation={0}
        sx={{ padding: 0 }}
      >
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={
            panelState.country ? <ExpandLessIcon /> : <ExpandMoreIcon />
          }
        >
          <Typography variant="body2" fontWeight={700} fontSize={14}>
            {t(FilterLocale.COUNTRY)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "2px",
            padding: 0,
          }}
        >
          <Checkbox
            sx={{ padding: 0 }}
            checked={
              checkAllCountry &&
              !resultSearchSelected.some((c) => c.type === ResultType.COUNTRY)
            }
            onChange={(e) => dispatch(setCheckAllCountry(e.target.checked))}
            icon={
              <Box
                sx={{
                  bgcolor: "#ffffff",
                  color: "#011A5B",
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  fontSize="12px"
                  textTransform="uppercase"
                >
                  {t(FilterLocale.ALL)}
                </Typography>
              </Box>
            }
            checkedIcon={
              <Box
                sx={{
                  bgcolor: "#011A5B",
                  color: "#ffffff",
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  fontSize="12px"
                  textTransform="uppercase"
                >
                  {t(FilterLocale.ALL)}
                </Typography>
              </Box>
            }
          />
          {country &&
            country.map((item, index) => (
              <Checkbox
                style={{ padding: "3px 9px" }}
                checked={resultSearchSelected.some(
                  (c) => c.name === item.name && c.type === ResultType.COUNTRY
                )}
                onChange={(e) => handleCountryFilter(e, index)}
                icon={
                  <Avatar src={`/assets/images/country/${item.name}.svg`} />
                }
                checkedIcon={
                  <Avatar
                    sx={{ width: 40, height: 40, bgcolor: "#011A5B" }}
                    src={`/assets/images/country/${item.name}.svg`}
                  />
                }
              />
            ))}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={panelState.city}
        onChange={() => handleChangePanel("city")}
        elevation={0}
        sx={{ padding: 0 }}
      >
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={panelState.city ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          <Typography variant="body2" fontWeight={700} fontSize={14}>
            {t(FilterLocale.CITY)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            padding: 0,
            paddingLeft: "10px",
          }}
        >
          <FormControlLabel
            sx={{ fontSize: "14px" }}
            label={t(FilterLocale.ALL_CITIES)}
            control={
              <Checkbox
                checked={checkAllCity}
                onChange={(e) => handleCheckAllCity(e.target.checked)}
              />
            }
          />
          {cities &&
            cities.map((item: ICity) => (
              <FormControlLabel
                sx={{ fontSize: "14px" }}
                label={getCityName(item.name)}
                control={
                  <Checkbox
                    disabled={resultSearchSelected.some(
                      (c) =>
                        c.type === ResultType.CITY &&
                        c.name === item.name &&
                        c.children.filter((c) => c.checked).length > 0
                    )}
                    checked={resultSearchSelected.some(
                      (co) =>
                        co.type === ResultType.COUNTRY &&
                        co.children.some(
                          (c) =>
                            c.name === item.name &&
                            c.checked &&
                            c.type === ResultType.CITY
                        )
                    )}
                    onChange={(e) => handleCityFilter(e, item)}
                  />
                }
              />
            ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={panelState.kitchen}
        onChange={() => handleChangePanel("kitchen")}
        elevation={0}
        sx={{ padding: 0 }}
      >
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={
            panelState.kitchen ? <ExpandLessIcon /> : <ExpandMoreIcon />
          }
        >
          <Typography variant="body2" fontWeight={700} fontSize={14}>
            {t(FilterLocale.KITCHEN)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            padding: 0,
            paddingLeft: "10px",
          }}
        >
          <FormControlLabel
            sx={{ fontSize: "14px" }}
            label={t(FilterLocale.ALL_KITCHENS)}
            control={
              <Checkbox
                checked={checkAllKitchen}
                onChange={(e) => handleCheckAllKitchen(e.target.checked)}
              />
            }
          />
          {kitchens &&
            kitchens.map((item: IKitchenItem) => (
              <FormControlLabel
                sx={{ fontSize: "14px" }}
                label={
                  <Typography textTransform="capitalize">
                    {item.name}
                  </Typography>
                }
                control={
                  <Checkbox
                    disabled={resultSearchSelected.some(
                      (c) =>
                        c.type === ResultType.KITCHEN && c.name === item.name
                    )}
                    checked={resultSearchSelected.some(
                      (co) =>
                        co.type === ResultType.COUNTRY &&
                        co.children.some(
                          (c) =>
                            c.name === item.city &&
                            c.type === ResultType.CITY &&
                            c.children.some(
                              (k) =>
                                k.name === item.name &&
                                k.checked &&
                                k.type === ResultType.KITCHEN
                            )
                        )
                    )}
                    onChange={(e) => handleKitchenFilter(e, item)}
                  />
                }
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default FilterMultipleChoice;
