import Kitchen from "../../entities/Kitchen.enitity";
import { KitchenProcessStrategy } from "../../interfaces/strategy/kitchen.strategy";

class KitchenProcessImpl {
  private strategy: KitchenProcessStrategy;

  constructor(strategy: KitchenProcessStrategy) {
    this.strategy = strategy;
  }

  public setStrategy(strategy: KitchenProcessStrategy) {
    this.strategy = strategy;
  }

  public execute(data: Kitchen[]): Kitchen[] {
    return this.strategy.execute(data);
  }
}

export default KitchenProcessImpl;
