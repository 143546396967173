import React from "react";
import { ThemeProvider } from "@emotion/react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Router from "presentation/Router";
import theme from "presentation/config/theme";
import DrawerMenuProvider from "presentation/contexts/DrawerMenu.context";
import initI18n from "../common/i18n";

interface AppProps {}

const App: React.FunctionComponent<AppProps> = () => {
  React.useEffect(() => {
    initI18n();
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DrawerMenuProvider>
          <Box className="App">
            {/* Good place for Snackbars and/or modals */}
            <Router />
          </Box>
        </DrawerMenuProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
