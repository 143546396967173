import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Icon,
} from "@mui/material";

import { useEffect, useState, FunctionComponent } from "react";
import Kitchen from "../../../domain/entities/Kitchen.enitity";
import { Platform } from "../../../domain/entities/Store.entity";
import { getCityName } from "../../utils/city";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  setCoverage,
  setVersion,
} from "../../../store/slices/mapsStateSlice/mapsStateSlice";
import useAppSelector from "../../hooks/useAppSelector";
import { timeHumanize } from "../../utils/timeHumanize";
import { Countries } from "../../../domain/entities/Country";
import Brand from "../../../domain/entities/Brand.entity";
import { COVERAGE_THEORICAL_DEFAULT } from "../../../domain/entities/Polygons";

export interface FilterMapParameters {
  brandId: string | null;
  city: string | null;
  kitchenId: string | null;
  platform: Platform | null;
}
interface IPlatformItems {
  country: string;
  name: string;
  value: string;
}
interface FilterMapsProps {
  baseData: Array<Kitchen> | null;
  defaultParameters: FilterMapParameters | undefined;
}
const FilterMaps: FunctionComponent<FilterMapsProps> = ({
  baseData,
  defaultParameters,
}) => {
  const dispatch = useAppDispatch();
  const coverage = useAppSelector((state) => state.mapsState.coverage);
  const [city, setCity] = useState<string>("");
  const [kitchenSelect, setKitchen] = useState<string>("");
  const [brandSelect, setBrandSelect] = useState<string>("");
  const [platformSelect, setPlatformSelect] = useState<string>("");
  const [areaTheorical, setAreaTheorical] = useState<number | null>(0);

  const [cityItem, setCityItem] = useState<Array<string>>([]);
  const [kitchenItem, setKitchenItem] = useState<Array<string | null>>([]);
  const [brandsItem, setBrandsItem] = useState<Array<string | null>>([]);
  const [platformItem, setPlatformItem] = useState<Array<IPlatformItems>>([]);

  const platformItemsConf: IPlatformItems[] = [
    {
      country: "ALL",
      value: "rappi",
      name: "RAPPI",
    },
    {
      country: "BRA",
      value: "ifood",
      name: "IFOOD",
    },
    {
      country: "MEX",
      value: "uber",
      name: "UBER",
    },
    {
      country: "MEX",
      value: "rappi",
      name: "RAPPI",
    },
  ];
  const onActionMapView = (kitchenId: string) => {
    const _platformSelect = platformSelect as Platform;
    const _kitchenSelect = kitchenId;
    setKitchen(kitchenId);
    if (baseData) {
      let coverageValue = [];

      if (kitchenId === "all") {
        const resultAll = baseData.filter((kitchen) => kitchen.city === city);
        if (resultAll.length > 0) {
          resultAll.forEach((kitchen) => {
            const brand = kitchen.brands.filter(
              (brand) =>
                brand.id === brandSelect &&
                brand.state[_platformSelect] !== null
            );
            if (brand.length > 0)
              coverageValue.push(brand[0].state[_platformSelect]?.coverage);
          });
        }
      } else {
        const result = baseData.filter(
          (kitchen) => kitchen.city === city && kitchen.id === _kitchenSelect
        );
        if (result.length > 0) {
          const brands = result[0].brands.filter((b) => b.id === brandSelect);

          const index = brands.findIndex(
            (b) => b.state[_platformSelect] !== null
          );
          if (index > -1) {
            coverageValue.push(brands[index].state[_platformSelect]?.coverage);
            setAreaTheorical(
              brands[index].state[_platformSelect]?.platformConfig
                ?.theoreticalCoverage.area ?? COVERAGE_THEORICAL_DEFAULT
            );
          }
        }
      }

      if (coverageValue.length > 0) {
        dispatch(setVersion({}));
        dispatch(setCoverage(coverageValue));
      } else {
        dispatch(setVersion({}));
        dispatch(
          setCoverage({
            area: 0,
            coordinates: [],
            coordinatesEvaluated: 0,
            kitchenCoordinate: {
              lat: 0,
              lng: 0,
            },
            lastUpdate: "",
            unit: "",
            version: 0,
          })
        );
      }
    }
  };

  const onChangeBrand = (val: string) => {
    setBrandSelect(val);
  };
  useEffect(() => {
    if (baseData && city?.length) {
      const result = baseData.filter((kitchen) => kitchen.city === city);
      if (result.length > 0) {
        const brands: Array<string | null> = [];
        result[0].brands.forEach((i) => {
          if (!brands.includes(i.id)) {
            brands.push(i.id);
          }
        });
        setBrandsItem(brands);
        if (result[0].country === Countries.MEXICO) {
          setPlatformItem(
            platformItemsConf.filter((i) => i.country === Countries.MEXICO)
          );
        } else if (result[0].country === Countries.BRASIL) {
          setPlatformItem(
            platformItemsConf.filter((i) => i.country === Countries.BRASIL)
          );
        } else {
          setPlatformItem(platformItemsConf.filter((i) => i.country === "ALL"));
        }
      }
    }
  }, [city]);

  const loadKitchenItems = (baseData: Kitchen[]) => {
    setKitchenItem(
      baseData
        .filter(
          (kitchen) =>
            kitchen.city === city &&
            kitchen.brands.some((brand: Brand) => brand.id === brandSelect)
        )
        .map((i) => i.id)
    );
  };

  useEffect(() => {
    if (baseData && baseData.length > 0) {
      let tempItemCity: Array<string> = [];

      baseData.forEach((kitchen: any) => {
        if (!tempItemCity.some((c) => c === kitchen.city)) {
          tempItemCity.push(kitchen.city);
        }
      });

      setCityItem(tempItemCity);
    }
  }, [baseData]);

  useEffect(() => {
    if (cityItem.length > 0) {
      if (defaultParameters?.city) {
        setCity(defaultParameters.city);
      }
    }
  }, [cityItem]);

  useEffect(() => {
    if (brandsItem.length > 0) {
      if (defaultParameters?.brandId) {
        setBrandSelect(defaultParameters.brandId);
      }
    }
  }, [city, brandsItem]);

  useEffect(() => {
    if (baseData && brandSelect?.length) {
      loadKitchenItems(baseData);
    }
  }, [brandSelect]);

  useEffect(() => {
    if (platformItem.length > 0) {
      if (defaultParameters?.platform) {
        setPlatformSelect(defaultParameters.platform);
      }
    }
  }, [platformItem]);

  useEffect(() => {
    if (kitchenItem.length > 0 && platformSelect.length > 0) {
      if (defaultParameters?.kitchenId) {
        setKitchen(defaultParameters.kitchenId);
        onActionMapView(defaultParameters.kitchenId);
      }
    }
  }, [kitchenItem, platformSelect]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Typography
        variant="h6"
        fontWeight="600"
        fontSize="16px"
        color="primary"
        textAlign="left"
        width="100%"
      >
        Filtro
      </Typography>
      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <InputLabel id="select-city">Seleccione ciudad*</InputLabel>
        <Select
          labelId="select-city"
          id="select-city"
          label="Seleccione ciudad*"
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
          }}
        >
          {cityItem.map((item) => (
            <MenuItem
              key={item}
              value={item}
              sx={{ textTransform: "uppercase" }}
            >
              {getCityName(item).toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <InputLabel id="select-brand">Seleccione marca*</InputLabel>
        <Select
          labelId="select-brand"
          id="select-brand"
          label="Seleccione marca*"
          value={brandSelect}
          onChange={(e) => onChangeBrand(e.target.value)}
        >
          {brandsItem.map((item) => (
            <MenuItem key={item} value={item || ""}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <InputLabel id="select-brand">Seleccione plataforma*</InputLabel>
        <Select
          labelId="select-platform"
          id="select-platform"
          label="Seleccione plataforma*"
          value={platformSelect}
          onChange={(e) => setPlatformSelect(e.target.value)}
        >
          {platformItem.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <InputLabel id="select-kitchen">Seleccione cocina*</InputLabel>
        <Select
          labelId="select-kitchen"
          id="select-kitchen"
          label="Seleccione cocina*"
          value={kitchenSelect}
          onChange={(e) => {
            onActionMapView(e.target.value);
          }}
        >
          <MenuItem key="all" value="all">
            TODOS
          </MenuItem>
          {kitchenItem.map((item) => (
            <MenuItem key={item} value={item || ""}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {coverage.length === 1 &&
        coverage[0].coordinates != null &&
        coverage[0].coordinates.length > 0 && (
          <>
            <Divider
              sx={{ width: "100%", borderColor: "#D7D3D3", mt: 1, mb: 2 }}
            />
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              gap={1}
            >
              <Box sx={{ display: "flex", alignContent: "center" }}>
                <Icon>info_black_outlined</Icon>
                <Typography
                  sx={{ ml: 1 }}
                  variant="h6"
                  fontWeight="600"
                  fontSize="16px"
                >
                  {brandSelect} - {kitchenSelect}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Área:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  {`${coverage[0].area.toFixed(2)} km2`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Área teórica:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  {`${areaTheorical} km2`}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Coords. evaluadas:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  {coverage[0].coordinatesEvaluated}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Última actualización:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  hace {timeHumanize(new Date(coverage[0].lastUpdate))}
                </Typography>
              </Box>
            </Box>
          </>
        )}
    </Box>
  );
};

export default FilterMaps;
