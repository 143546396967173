/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getState = /* GraphQL */ `
  query GetState($universalId: String!) {
    getState(universalId: $universalId) {
      lastUpdate
      platform
      state
      type
      universalId
    }
  }
`;
export const listCity = /* GraphQL */ `
  query ListCity($filter: TableStateFilterInput, $limit: Int) {
    listCity(filter: $filter, limit: $limit) {
      items {
        id
        name
      }
    }
  }
`;
export const listKitchenBrand = /* GraphQL */ `
  query ListKitchenBrand($nextToken: String, $limit: Int) {
    listKitchenBrand(nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        brandId
        brandName
        city
        country
        pedidosyaId
        pedidosyaState {
          universalId
          type
          state
          platform
          lastUpdate
          schedule
          coverage
          enabled
        }
        didiId
        didiState {
          universalId
          type
          state
          platform
          lastUpdate
          schedule
          coverage
          enabled
        }
        ifoodId
        ifoodState {
          universalId
          type
          state
          platform
          lastUpdate
          schedule
          coverage
          enabled
        }
        kitchenId
        kitchenName
        rappiId
        rappiState {
          universalId
          type
          state
          platform
          lastUpdate
          schedule
          coverage
          enabled
        }
        storeId
        uberId
        uberState {
          universalId
          type
          state
          platform
          lastUpdate
          schedule
          coverage
          enabled
        }
        universalId
        kitchen {
          activeOrders
          protocols
          peopleActive
        }
      }
    }
  }
`;

export const ListStores = /* GraphQL */ `
  query listStores(
    $filter: TableStoreFilterInput
    $nextToken: String
    $limit: Int
  ) {
    listStores(filter: $filter, nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        kitchenId
        coverage {
          area
          coordinates {
            lat
            lng
          }
          coordinatesEvaluated
          lastUpdate
          unit
          kitchenCoordinate {
            lat
            lng
          }
        }
        brandId
        city
        country
        platformConfig {
          isMarketPlace
          isParentStore
          theoreticalCoverage {
            area
            unit
          }
        }
        kitchen {
          activeOrders
          name
          kitchenId
          peopleActive
          protocols
          tier
          kitchenSetup {
            country
            endMarkTurn
            saturday
            monday
            startMarkTurn
            sunday
            thursday
            tier
            tuesday
            friday
            universalId
            wednesday
            type
          }
        }
        lastUpdate
        platform
        schedule {
          timeZone
          week {
            friday
            monday
            saturday
            sunday
            thursday
            tuesday
            wednesday
          }
        }
        status {
          internal
          lastUpdate
          platform
          reason
        }
        universalId
        warehouseId
      }
    }
  }
`;

export const listStoreWithCampaigns = /* GraphQL */ `
  query ListStoreWithCampaigns(
    $filter: TableStoreFilterInput
    $nextToken: String
    $limit: Int
  ) {
    listStores(filter: $filter, nextToken: $nextToken, limit: $limit) {
      items {
        universalId
        campaigns
        kitchenId
        country
        brandId
      }
      nextToken
    }
  }
`;
export const listState = /* GraphQL */ `
  query ListState($filter: TableStateFilterInput, $limit: Int) {
    listState(filter: $filter, limit: $limit) {
      items {
        lastUpdate
        platform
        state
        type
        universalId
      }
    }
  }
`;

export const listCities = /* GraphQL */ `
  query ListCities($filter: TableCityFilterInput, $limit: Int) {
    listCities(filter: $filter, limit: $limit) {
      items {
        id
        name
        country
      }
    }
  }
`;

export const listBrands = /* GraphQL */ `
  query ListBrands($filter: TableBrandFilterInput, $limit: Int) {
    listBrands(filter: $filter, limit: $limit) {
      items {
        brandId
        brandName
        countries
      }
    }
  }
`;

export const listKitchens = /* GraphQL */ `
  query listKitchens($filter: TableKitchenFilterInput, $limit: Int) {
    listKitchens(filter: $filter, limit: $limit) {
      items {
        activeOrders
        city
        country
        kitchenId
        name
        peopleActive
        protocols
        type
      }
    }
  }
`;
