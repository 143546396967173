import { createSlice } from "@reduxjs/toolkit";

interface UserState {
  title: string | null;
}

const initialState: UserState = {
  title: null,
};

export const screenSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

export const { setTitle } = screenSlice.actions;

export default screenSlice.reducer;
