import { createSlice } from "@reduxjs/toolkit";

export interface ICountry {
  name: string;
}
export interface ICity {
  name: string;
  country: string;
}

export interface IKitchenItem {
  country: string;
  city: string;
  name: string;
}

export interface FilterState {
  countries: [];
  cities: [];
  kitchens: [];
  kitchensAll: IKitchenItem[];
  countriesAll: ICountry[];
  citiesAll: ICity[];
  kitchenItems: IKitchenItem[];
  countFilter: number;
  checkAllCountry: boolean;
  checkAllCity: boolean;
  checkAllKitchen: boolean;
  resultSearch: ISearchResult[];
  resultSearchSelected: ISearchResult[];
}

export enum ResultType {
  COUNTRY,
  CITY,
  KITCHEN,
}
export interface ISearchResult {
  type: ResultType;
  name: string;
  checked: boolean;
  children: ISearchResult[];
}

const initialState: FilterState = {
  countries: [],
  countriesAll: [],
  cities: [],
  citiesAll: [],
  kitchens: [],
  kitchensAll: [],
  kitchenItems: [],
  countFilter: 0,
  checkAllCountry: true,
  checkAllCity: true,
  checkAllKitchen: true,
  resultSearch: [],
  resultSearchSelected: [],
};
export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setKitchenItems: (state, action) => {
      state.kitchenItems = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setKitchens: (state, action) => {
      state.kitchens = action.payload;
    },
    setCountFilter: (state, action) => {
      state.countFilter = action.payload;
    },
    setCheckAllCountry: (state, action) => {
      state.checkAllCountry = action.payload;
    },
    setCheckAllCity: (state, action) => {
      state.checkAllCity = action.payload;
    },
    setCheckAllKitchen: (state, action) => {
      state.checkAllKitchen = action.payload;
    },
    setCountriesAll: (state, action) => {
      state.countriesAll = action.payload;
    },
    setCitiesAll: (state, action) => {
      state.citiesAll = action.payload;
    },
    setKitchensAll: (state, action) => {
      state.kitchensAll = action.payload;
    },
    setResultSearch: (state, action) => {
      state.resultSearch = action.payload;
    },
    setFilterSelected: (state, action) => {
      state.resultSearchSelected = action.payload;
    },
    updateCheckedCity: (
      state,
      { payload: { indexCountry, indexCity, checked } }
    ) => {
      state.resultSearchSelected[indexCountry].children[indexCity].checked =
        checked;
      state.resultSearchSelected[indexCountry].children[indexCity].children.map(
        (item) => (item.checked = checked)
      );
    },
    updateCheckedKitchen: (
      state,
      { payload: { indexCountry, indexCity, indexKitchen, checked } }
    ) => {
      state.resultSearchSelected[indexCountry].children[indexCity].children[
        indexKitchen
      ].checked = checked;
    },
    deleteFilterSelectedByIndex: (state, action) => {
      state.resultSearchSelected.splice(action.payload, 1);
    },
    changeCheckedResulSearch: (state, { payload: { item, checked } }) => {
      let index = state.resultSearchSelected.findIndex(
        (c) => c.name === item.name && c.type === item.type
      );
      let indexSearch = state.resultSearch.findIndex(
        (c) => c.name === item.name && c.type === item.type
      );

      if (state.resultSearch[indexSearch]) {
        state.resultSearch[indexSearch].checked = checked;
      }

      if (checked) {
        if (index === -1) {
          state.resultSearchSelected.push(state.resultSearch[indexSearch]);
          index = state.resultSearchSelected.length - 1;
        }
        state.resultSearch[indexSearch].children?.map((item) => {
          item.checked = true;
          return item;
        });
        state.resultSearchSelected[index].children?.map((item) => {
          item.checked = true;
          return item;
        });
      } else {
        //eliminar item
        if (state.resultSearch[indexSearch]) {
          state.resultSearch[indexSearch].children?.map((item) => {
            item.checked = false;
            return item;
          });
        }
        const findIndex = state.resultSearchSelected.findIndex(
          (s) => s.name === item.name && s.type === item.type
        );
        state.resultSearchSelected.splice(findIndex, 1);
      }
    },
    changeCheckedChildrenResulSearch: (
      state,
      { payload: { item, indexChild, checked } }
    ) => {
      let index = state.resultSearchSelected.findIndex(
        (c) => c.name === item.name && c.type === item.type
      );
      let indexSearch = state.resultSearch.findIndex(
        (c) => c.name === item.name && c.type === item.type
      );

      if (!state.resultSearchSelected[index]) {
        index = state.resultSearchSelected.length;
        state.resultSearchSelected.push(state.resultSearch[indexSearch]);
      }
      if (
        state.resultSearch[indexSearch] &&
        state.resultSearch[indexSearch].children[indexChild]
      ) {
        state.resultSearch[indexSearch].children[indexChild].checked = checked;
      }

      state.resultSearchSelected[index].children[indexChild].checked = checked;
      state.resultSearchSelected[index].children[indexChild].children.map(
        (item) => {
          item.checked = checked;
          return item;
        }
      );

      if (!checked) {
        if (state.resultSearch[indexSearch]) {
          state.resultSearch[indexSearch].checked = false;
        }
        state.resultSearchSelected[index].checked = false;

        const total = state.resultSearchSelected[index].children.filter(
          (item) => item.checked
        ).length;
        if (total === 0) {
          state.resultSearchSelected.splice(index, 1);
        }
      }
    },
  },
});

export const {
  setCountries,
  setCities,
  setKitchens,
  setKitchenItems,
  setCountFilter,
  setCheckAllCity,
  setCheckAllCountry,
  setCheckAllKitchen,
  setResultSearch,
  changeCheckedResulSearch,
  changeCheckedChildrenResulSearch,
  setCountriesAll,
  setCitiesAll,
  setKitchensAll,
  setFilterSelected,
  deleteFilterSelectedByIndex,
  updateCheckedCity,
  updateCheckedKitchen,
} = filterSlice.actions;
export default filterSlice.reducer;
