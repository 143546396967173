export enum SummaryPageLocale {
  // generic
  TITLE = "summary.page.title",
  DESCRIPTION = "summary.page.description",
  ATENTION = "summary.page.atention",
  OK = "summary.page.ok",

  //grid
  KITCHEN = "summary.grid.kitchen",
  BRANDS = "summary.grid.brands",
  POLYGONS = "summary.grid.polygons",
  NO_BRANDS_AVAILABLE = "summary.grid.no_brands_available",
  NO_BRANDS_COVERAGE = "summary.grid.no_brands_coverage",
  WITHOUT_INFORMATION = "summary.grid.without_information",

  //filters
  BUTTON_EDIT_COLUMNS = "summary.filters.columns",

  //Drawers
  DRAWER_COLUMN_TITLE = "drawer.column.title",
  DRAWER_COLUMN_DESCRIPTION = "drawer.column.description",
  DRAWER_COLUMN_CARD_TITLE = "drawer.column.card.title",
  DRAWER_COLUMN_CARD_DESCRIPTION = "drawer.column.card.description",
  DRAWER_COLUMN_BRAND_IN = "drawer.column.brand_in",
  DRAWER_COLUMN_COVERAGE_IN = "drawer.column.coverage_in",
  DRAWER_COLUMN_BRAND_DESCRIPTION_COVERAGE = "drawer.column.brand.description.coverage",
  DRAWER_COLUMN_BRAND_DESCRIPTION_POLYGON = "drawer.column.brand.description.polygon",

  SHOPS = "summary.shops",
  BRAND = "summary.brand",
  LAST_UPDATE = "summary.last_update",
  IN_SCHEDULE = "summary.in_schedule",
  OUT_SCHEDULE = "summary.out_schedule",
  OFF = "summary.off",
  ON = "summary.on",
  SHOULD_OFF = "summary.should_off",
  SHOULD_OFF_BY_SCHEDULE = "summary.should_off_by_schedule",
  SHOULD_ON = "summary.should_on",
  SHOULD_ON_BY_SCHEDULE = "summary.should_on_by_schedule",
  SCHEDULE_DEFINED = "summary.schedule_defined",
  MOTIVE = "summary.motive",
  AGO = "summary.ago",
  DAYS = "summary.days",
  NOW = "summary.now",
  MINUTES = "summary.minutes",
}
