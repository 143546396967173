import * as Realm from "realm-web";
import RealmClient from "domain/interfaces/clients/realm.client";
import Config from "config.provider";
class RealmClientImpl implements RealmClient {
  private client: Realm.User | null;
  private app: Realm.App;
  private credentials: Realm.Credentials;
  constructor() {
    this.app = new Realm.App({
      id: Config.realm.appId,
    });
    this.credentials = Realm.Credentials.serverApiKey(Config.realm.apiKey);
    this.client = null;
  }
  getClient(): globalThis.Realm.Services.MongoDB | null {
    return this.client
      ? this.client.mongoClient(Config.realm.serviceNameProd)
      : null;
  }

  async connect(): Promise<any> {
    this.client = await this.app.logIn(this.credentials);
  }
}

export default RealmClientImpl;
