import React from "react";

export interface IDrawerMenuContext {
  open: boolean;
  toggle: () => void;
  setDrawer: (state: boolean) => void;
}

interface DrawerMenuProviderProps {
  children: React.ReactChild;
}

const defaultState = {
  open: false,
  toggle: () => {},
  setDrawer: (state: boolean) => {},
};

export const DrawerMenuContext =
  React.createContext<IDrawerMenuContext>(defaultState);

const DrawerMenuProvider: React.FunctionComponent<DrawerMenuProviderProps> = (
  props
) => {
  const { children } = props;

  const [open, setOpen] = React.useState(defaultState.open);

  const toggle = () => {
    setOpen(!open);
  };

  const setDrawer = (state: boolean) => {
    setOpen(state);
  };

  return (
    <DrawerMenuContext.Provider
      value={{
        open,
        toggle,
        setDrawer,
      }}
    >
      {children}
    </DrawerMenuContext.Provider>
  );
};

export default DrawerMenuProvider;
