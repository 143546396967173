import { Session } from "../../entities/Session";
import { CanGetUser } from "../../interfaces/account/canGetUser";

export class GetUserUseCase {
  constructor(private readonly repository: CanGetUser) {}

  public execute(session: Session) {
    return this.repository.getUser(session);
  }
}
