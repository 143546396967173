import { createAsyncThunk } from "@reduxjs/toolkit";
import Kitchen from "../../../domain/entities/Kitchen.enitity";
import { getKitchenState } from "../../commons";

export const getKitchensStateMaps = createAsyncThunk<Array<Kitchen>, string>(
  "mapsState/getKitchensState",
  async (state: string) => {
    const itemsKitchenOrder = await getKitchenState();

    if (state !== "ALL") {
      return itemsKitchenOrder.filter(
        (item) => item.type === null && item.country === state
      );
    } else {
      return itemsKitchenOrder.filter((item) => item.type === null);
    }
  }
);
