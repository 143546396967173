import Brand from "../../domain/entities/Brand.entity";
import Kitchen from "../../domain/entities/Kitchen.enitity";
import { Platform } from "../../domain/entities/Store.entity";

export const getStoreIdsByPlatform = (
  kitchen: Kitchen,
  platform: Platform
): Array<string | null | undefined> => {
  if (platform === undefined) return [];
  const storeIds = kitchen.brands
    .map((brand: Brand) =>
      brand.state[platform] ? brand.state[platform]?.storeId : null
    )
    .filter((id: string | null | undefined) => id !== null && id !== undefined);
  return storeIds;
};
