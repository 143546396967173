import { OrderActiveGroupDrawer } from "domain/entities/Order";
import Kitchen from "domain/entities/Kitchen.enitity";
import { AsyncState } from "../../types";

export enum OrderStateDrawers {
  ORDERS_ACTIVE = "orderActiveDrawer",
}

export enum OrderMapToUpdate {
  BASE_UPDATE,
  PEOPLE_ACTIVE,
  ACTIVE_ORDERS,
}

export interface OrderState {
  drawers: {
    [OrderStateDrawers.ORDERS_ACTIVE]: {
      open: boolean;
      kitchenId: string;
      status: AsyncState;
      data: Array<OrderActiveGroupDrawer>;
    };
  };
  kitchenState: {
    status: AsyncState;
    baseData: Kitchen[] | null;
    filteredData: Kitchen[] | null;
  };
}
