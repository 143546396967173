import { Platform, StatusStore } from "../domain/entities/Store.entity";
import { Schedule } from "../gateways/amplify/types";

export enum AsyncState {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

export enum TypeScope {
  ALL = "ALL",
  ECU = "ECU",
  PER = "PER",
  COL = "COL",
  MEX = "MEX",
  BRA = "BRA",
}

export enum TypeDeviceInPage {
  UNKNOW = "unknow",
  MOBIL = "mobil",
  TABLET = "tablet",
  DESKTOP = "desktop",
}

export enum TypeKitchen {
  PRODUCTION_CENTER = "PRODUCTION_CENTER",
}

export enum EventState {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export type EventUpdateBase = {
  lastUpdate: string;
  platform: Platform;
  state: string;
  universalId: string;
  reasonState?: string | null;
  enabled: boolean;
  schedule: Schedule;
  coverage: string;
  status: StatusStore;
};

export type EventKitchenUpdate = {
  kitchenId: string;
  activeOrders?: string | null;
  peopleActive?: string | null;
  country?: TypeScope;
};
