import Kitchen from "./../../entities/Kitchen.enitity";
import KitchenGateway from "./../../interfaces/gateways/kitchen.gateway";

export interface GetKitchensStateUseCase {
  exec(): Promise<Array<Kitchen>>;
}

class GetKitchensStateUseCaseImpl {
  private gateway: KitchenGateway;

  constructor(gateway: KitchenGateway) {
    this.gateway = gateway;
  }

  exec() {
    return this.gateway.getKitchensState();
  }
}

export default GetKitchensStateUseCaseImpl;
