import StoreGateway from "domain/interfaces/gateways/store.gateway";

export interface GetStoresStateUseCase {
  exec(): void;
}

export class GetStoresStateUseCaseImpl implements GetStoresStateUseCase {
  private gateway;

  constructor(gateway: StoreGateway) {
    this.gateway = gateway;
  }

  exec() {
    return this.gateway.getStoresWithCampaigns();
  }
}

export default GetStoresStateUseCaseImpl;
