import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import appConfig from "../../config.provider";
import { User } from "../../domain/entities/User";
import { AsyncState } from "../types";
import { Session } from "../../domain/entities/Session";
import { GetUserUseCase } from "../../domain/useCases/account/getUser";
import { UserHTTPGateway } from "../../gateways/http/user";

interface UserState {
  user: User | null;
  status: AsyncState;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  status: AsyncState.IDLE,
  error: null,
};

export const getUser = createAsyncThunk(
  "user/getUser",
  async (session: Session) => {
    const httpRepository = new UserHTTPGateway(appConfig);
    const getUserUseCase = new GetUserUseCase(httpRepository);
    const user = getUserUseCase.execute(session);
    return user;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, (state, action) => {
        state.status = AsyncState.LOADING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = AsyncState.SUCCESS;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = AsyncState.ERROR;
        state.error = action.error.message ?? null;
      });
  },
});

export default userSlice.reducer;
