import { createAsyncThunk } from "@reduxjs/toolkit";
import GetOrderActiveGroupImpl from "domain/useCases/order/getOrderActiveGroup.usecase";
import RealmOrderActiveGateway from "gateways/realm/gateways/orderActive.gateway";
import { getKitchenState } from "../../commons";
import Kitchen from "../../../domain/entities/Kitchen.enitity";

export const getOrderActiveState = createAsyncThunk(
  "orderState/getOrderActiveState",
  async (kitchenId: string) => {
    const gatewway = new RealmOrderActiveGateway();
    const usecase = new GetOrderActiveGroupImpl(gatewway);
    const data = usecase.exec(kitchenId);
    return data;
  }
);

export const getKitchensStateOrder = createAsyncThunk<Array<Kitchen>, string>(
  "orderState/getKitchensState",
  async (state: string) => {
    const itemsKitchenOrder = await getKitchenState();

    if (state !== "ALL") {
      return itemsKitchenOrder.filter(
        (item) => item.type === null && item.country === state
      );
    } else {
      return itemsKitchenOrder.filter((item) => item.type === null);
    }
  }
);
