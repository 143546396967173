import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Cookies from "js-cookie";
import config from "config.provider";
import { KOSMenu, MenuItem } from "@foodology-co/alejandria";
import { useNavigate } from "react-router-dom";
import Package from "../../../package.json";
import {
  DrawerMenuContext,
  IDrawerMenuContext,
} from "presentation/contexts/DrawerMenu.context";

import AppBar from "presentation/components/AppBar";

import { Typography } from "@mui/material";
import { setScope } from "../../store/slices/commonSlice";
import useAppSelector from "../hooks/useAppSelector";
import { roleList } from "../utils/role";
import { getUserMenu } from "../../store/slices/menu.slice";
import useAppDispatch from "../hooks/useAppDispatch";

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<AppLayoutProps> = (props) => {
  const { children } = props;

  const drawerMenu = React.useContext<IDrawerMenuContext>(DrawerMenuContext);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const session = useAppSelector((state) => state.session.session);
  const menu = useAppSelector((state) => state.menu.items);

  const navigate = useNavigate();

  const onClickMenuItem = (to: string | undefined) => {
    if (to && (to.includes("http") || to.includes("https"))) {
      window.location.href = to;
    } else if (to) {
      navigate(to);
    }
  };

  const validarVersionAndClear = () => {
    const version = localStorage.getItem("monitor.version");
    if (version !== Package.version) {
      localStorage.clear();
      localStorage.setItem("monitor.version", Package.version);
    }
  };

  useEffect(() => {
    validarVersionAndClear();
  }, []);

  const onBackAction = () => {
    window.history.back();
  };

  useEffect(() => {
    if (session) {
      dispatch(getUserMenu(session));
    }
  }, [dispatch, session]);
  useEffect(() => {
    const scope = Cookies.get("scope") || "ALL";
    dispatch(setScope(scope));
  }, [dispatch]);

  return (
    <Box display="flex" pt={7}>
      <AppBar />

      {drawerMenu.open && (
        <KOSMenu
          user={user ? { ...user, rolName: roleList[user.rolName] } : undefined}
          drawerOpen={drawerMenu.open}
          items={menu}
          onClickMenuItem={onClickMenuItem}
          onBackAction={onBackAction}
          isBackButtonEnable
          homeUrl={config.kitchenDisplay.url + "/home"}
          sx={{ zIndex: 14 }}
        />
      )}

      <Box flexGrow={1} minHeight="calc(100vh - 56px)" overflow="auto">
        {children}
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          right: "5px",
          display: { xs: "none", md: "flex" },
        }}
      >
        <Typography variant="body2" fontWeight="500" color="primary">
          v{Package.version}
        </Typography>
      </Box>
    </Box>
  );
};

export default AppLayout;
