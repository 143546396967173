import { Paper } from "@mui/material";
import { FunctionComponent } from "react";
interface PaperTableProps {
  children: React.ReactNode;
  height?: string;
}
const PaperTable: FunctionComponent<PaperTableProps> = ({
  height,
  children,
}) => {
  return (
    <Paper
      sx={{
        display: "flex",
        overflow: "auto",
        height: `${!height ? "200px" : height}`,
        flexDirection: "column",
        flexGrow: 1,
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#F9FAFC",
          borderBottom: "2px solid #E3E5E8",

          ".MuiDataGrid-columnHeaderTitle": {
            textTransform: "uppercase",
            color: "text.secondary",
            fontWeight: 700,
          },

          ".MuiDataGrid-columnSeparator": {
            color: "#D7D3D3",
          },

          ".MuiDataGrid-cell": {
            paddingX: 2,
          },
        },
        "& .MuiDataGrid-footerContainer": {
          minHeight: "inherit",

          "& .MuiTablePagination-toolbar": {
            minHeight: "inherit",
            height: "inherit",

            "& .MuiTablePagination-displayedRows": {
              my: 0,
            },
          },
        },
      }}
      elevation={0}
    >
      {children}
    </Paper>
  );
};

export default PaperTable;
