import { ResourceLanguage } from "i18next";
import { AlarmsLocale } from "../../../domain/interfaces/i18n/alarms.interface";
import { FilterLocale } from "../../../domain/interfaces/i18n/filters.interface";
import { OrderPageLocale } from "../../../domain/interfaces/i18n/order.interface";
import { SummaryPageLocale } from "../../../domain/interfaces/i18n/summary.interface";
import { SuppliesSummaryPageLocale } from "../../../domain/interfaces/i18n/suppliesSummary.interface";

const ptLocale: ResourceLanguage = {
  [SummaryPageLocale.TITLE]: "Resumo Lojas",
  [SummaryPageLocale.DESCRIPTION]:
    "Personalize os dados que deseja exibir na tabela",
  [SummaryPageLocale.ATENTION]: "Atenção",
  [SummaryPageLocale.OK]: "Ok",

  [SummaryPageLocale.KITCHEN]: "Cozinha",
  [SummaryPageLocale.BRANDS]: "Marcas",
  [SummaryPageLocale.POLYGONS]: "Polígonos",
  [SummaryPageLocale.NO_BRANDS_AVAILABLE]: "Não há marcas nesta plataforma",
  [SummaryPageLocale.NO_BRANDS_COVERAGE]: "Nenhuma informação de cobertura",
  [SummaryPageLocale.WITHOUT_INFORMATION]: "Sem informação",

  [SummaryPageLocale.BUTTON_EDIT_COLUMNS]: "Editar colunas",
  [FilterLocale.BUTTON]: "Filtros",
  [FilterLocale.TITLE]: "Filtros",
  [FilterLocale.ALL]: "Todos",
  [FilterLocale.SEARCH_TEXT]: "Pesquise por cidade ou cozinha",
  [FilterLocale.ALL_CITIES]: "Todas as cidades",
  [FilterLocale.ALL_KITCHENS]: "Todas as cozinha",
  [FilterLocale.COUNTRY]: "Países",
  [FilterLocale.CITY]: "Cidade",
  [FilterLocale.KITCHEN]: "Cozinha",

  [SummaryPageLocale.DRAWER_COLUMN_TITLE]: "Editar colunas",
  [SummaryPageLocale.DRAWER_COLUMN_DESCRIPTION]:
    "Ocultar ou adicionar colunas na tabela",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_TITLE]: "Configuração da coluna",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_DESCRIPTION]:
    "Escolha as colunas que deseja ocultar",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_IN]: "Marcas em",
  [SummaryPageLocale.DRAWER_COLUMN_COVERAGE_IN]: "Cobertura",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_COVERAGE]:
    "Marcas on e off",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_POLYGON]:
    "Cobertura poligonal de marcas",

  [SummaryPageLocale.SHOPS]: "Lojas",
  [SummaryPageLocale.BRAND]: "Marca",
  [SummaryPageLocale.LAST_UPDATE]: "Última atualização",
  [SummaryPageLocale.IN_SCHEDULE]: "Dentro de horas",
  [SummaryPageLocale.OUT_SCHEDULE]: "Fora de horas",
  [SummaryPageLocale.OFF]: "Off",
  [SummaryPageLocale.ON]: "On",
  [SummaryPageLocale.SHOULD_OFF]: "Deve estar off",
  [SummaryPageLocale.SHOULD_OFF_BY_SCHEDULE]: "Deve estar fora de programação",
  [SummaryPageLocale.SHOULD_ON]: "Deve estar on",
  [SummaryPageLocale.SHOULD_ON_BY_SCHEDULE]: "Deve ser on por programação",
  [SummaryPageLocale.SCHEDULE_DEFINED]: "Horário definido:",
  [SummaryPageLocale.MOTIVE]: "Razão:",
  [SummaryPageLocale.AGO]: "atrás",
  [SummaryPageLocale.DAYS]: "días",
  [SummaryPageLocale.NOW]: "Agora",
  [SummaryPageLocale.MINUTES]: "min",

  [OrderPageLocale.TITLE]: "Pedidos ativos",
  [OrderPageLocale.DESCRIPTION]:
    "Conheça ao vivo os dados dos pedidos de cozinha",
  [OrderPageLocale.COOKING]: "Cozinhar",
  [OrderPageLocale.PACKING]: "Embalagem",
  [OrderPageLocale.TO_DELIVER]: "Para entrega",
  [OrderPageLocale.ORDER_RT]: "Com entregador",
  [OrderPageLocale.ORDER_WITHOUT_RT]: "Sem entregador",
  [OrderPageLocale.ORDER_LATER]: "Pedidos atrasados",
  [OrderPageLocale.PEOPLE_IN_KITCHEN_RESUMEN]: "Pessoas na cozinha",
  [OrderPageLocale.PEOPLE_IN_KITCHEN]: "Pessoal na cozinha vs realizado",

  [OrderPageLocale.KITCHEN]: "Cozinha",
  [OrderPageLocale.ORDER_ACTIVE]: "Pedidos ativos",
  [OrderPageLocale.LAST_UPDATE]: "Última atualização",
  [OrderPageLocale.IN_STATION]: "Em estação",
  [OrderPageLocale.IN_QUEUE]: "Em fila e culinária",
  [OrderPageLocale.OK]: "Ok",
  [OrderPageLocale.ATENTION]: "Atenção",

  [OrderPageLocale.PLATFORM]: "PLATAFORMA",
  [OrderPageLocale.BRAND]: "MARCA",
  [OrderPageLocale.TOTAL_ORDERS]: "TOTAL DE PEDIDOS",
  [OrderPageLocale.MAIN_PLATE]: "PRATOS FORTES",

  [OrderPageLocale.DRAWER_ORDERS_TITLE]: "Pedidos ativos",
  [OrderPageLocale.DRAWER_ORDERS_DESCRIPTION]: "Ver pedidos na estação",

  [OrderPageLocale.DRAWER_DELIVER_TITLE]: "Para entrega",
  [OrderPageLocale.DRAWER_DELIVER_DESCRIPTION]:
    "Veja os pedidos que estão prontos e ainda não foram entregues",

  [OrderPageLocale.PERSONAL]: "PESSOAL",
  [OrderPageLocale.SCHEDULE_IN]: "ENTRADA EM",
  [OrderPageLocale.DATE_IN]: "Data de admissão",
  [OrderPageLocale.HOURS_IN_KITCHEN]: "Horas na cozinha",
  [OrderPageLocale.DRAWER_PEOPLE_TITLE]: "Pessoal na cozinha",
  [OrderPageLocale.DRAWER_PEOPLE_DESCRIPTION]:
    "Veja as pessoas ativas no turno",

  [OrderPageLocale.PEOPLE_CARD_TITLE]: "Pessoal na cozinha",
  [OrderPageLocale.PEOPLE_CARD_DESCRIPTION]:
    "Cozinheiros ou chefs com turnos ativos",
  [OrderPageLocale.PEOPLE_CARD_CORP_TITLE]: "Pessoal corporativo",
  [OrderPageLocale.PEOPLE_CARD_CORP_DESCRIPTION]:
    "Quality, Ops, etc, com turno ativo na cozinha",

  [AlarmsLocale.TITLE]: "Alarmes",
  [AlarmsLocale.SUBTITLE]:
    "Assine os alarmes que deseja receber de eventos nas cozinhas para ficar atualizado",
  [AlarmsLocale.FORM_TITLE]: "Criar um novo alarme",
  [AlarmsLocale.FORM_EVENT]: "Evento*",
  [AlarmsLocale.FORM_EVENT_LABEL]:
    "Evento por el que recibirás la notificación",
  [AlarmsLocale.FORM_EVENT_PLACEHOLDER]: "Escolha o evento",
  [AlarmsLocale.EVENT_ON]: "On e Off",
  [AlarmsLocale.EVENT_POLYGONS]: "Polígonos",
  [AlarmsLocale.FORM_CHANNEL]: "Canal*",
  [AlarmsLocale.FORM_CHANNEL_LABEL]:
    "Apenas WhatsApp disponível, em breve você poderá escolher outros canais",
  [AlarmsLocale.FORM_COUNTRY]: "País",
  [AlarmsLocale.FORM_COUNTRY_LABEL]: "",
  [AlarmsLocale.FORM_COUNTRY_PLACEHOLDER]: "Escolha o país",
  [AlarmsLocale.FORM_CITY]: "Cidade",
  [AlarmsLocale.FORM_CITY_LABEL]: "",
  [AlarmsLocale.FORM_CITY_PLACEHOLDER]: "Escolha a cidade",
  [AlarmsLocale.FORM_BRAND]: "Marcas",
  [AlarmsLocale.FORM_BRAND_LABEL]: "",
  [AlarmsLocale.FORM_BRAND_PLACEHOLDER]: "Escolha a marca",
  [AlarmsLocale.FORM_PHONE_NUMBER]: "Número de telefone *",
  [AlarmsLocale.FORM_PHONE_NUMBER_LABEL]:
    "Número para o qual enviaremos a notificação",
  [AlarmsLocale.FORM_PHONE_NUMBER_PLACEHOLDER]: "Número de telefone",
  [AlarmsLocale.FORM_SUBMIT]: "CRIAR ALARME",
  [AlarmsLocale.ALL]: "Todos",

  [AlarmsLocale.NEW_ALARM_TEXT]:
    "Entre em “Criar um novo alarme” para começar a receber alertas sobre o estado das suas cozinhas",
  [AlarmsLocale.NEW_ALARM_BUTTON]: "CRIAR UM NOVO ALARME",
  [AlarmsLocale.NEW_ALARM_SUCCESS]: "Alarme criado com sucesso",
  [AlarmsLocale.CONTINUE]: "Continuar",
  [AlarmsLocale.PHONE]: "Telefone",
  [AlarmsLocale.ACTIONS]: "Ações",
  [AlarmsLocale.PREV_EVENTS]: "Criado anteriormente",
  [AlarmsLocale.ACTIVE]: "Ativo",
  [AlarmsLocale.RECEIVED_ALARM_COPY1]: "Você receberá notificações em",
  [AlarmsLocale.RECEIVED_ALARM_COPY2]: "sobre",
  [AlarmsLocale.RECEIVED_ALARM_COPY3]: "de",
  [AlarmsLocale.RECEIVED_ALARM_COPY4]: "em",
  [AlarmsLocale.RECEIVED_ALARM_COPY5]: "al",

  [SuppliesSummaryPageLocale.TITLE]: "Resumen Insumos",
  [SuppliesSummaryPageLocale.DESCRIPTION]:
    "Personaliza los datos que deseas visualizar en la tabla",
  [SuppliesSummaryPageLocale.TOTALOFF]: "Total apagados",
  [SuppliesSummaryPageLocale.TYPEAA]: "Tipo AA",
  [SuppliesSummaryPageLocale.TYPEA]: "Tipo A",
  [SuppliesSummaryPageLocale.TYPEB]: "Tipo B",
  [SuppliesSummaryPageLocale.TYPEC]: "Tipo C",
  [SuppliesSummaryPageLocale.KITCHEN]: "Cocina",
};

export default ptLocale;
