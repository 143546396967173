import React from "react";

interface AAIconProps {
  color?: string;
  width?: string;
}

const AAIcon = (props: AAIconProps): React.ReactElement => {
  const { color, width } = props;
  return (
    <svg
      width={!width ? "61" : width}
      height="32"
      viewBox="0 0 61 32"
      fill={!color ? "#E65100" : color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.7836 5.47253L7.08033 32H0.0693359L11.9594 0H16.421L15.7836 5.47253ZM23.0144 32L14.2891 5.47253L13.5858 0H18.0913L30.0474 32H23.0144ZM22.6188 20.0879V25.2527H5.71769V20.0879H22.6188Z" />
      <path d="M46.0254 5.47253L37.3221 32H30.3111L42.2012 0H46.6627L46.0254 5.47253ZM53.2561 32L44.5309 5.47253L43.8276 0H48.3331L60.2891 32H53.2561ZM52.8605 20.0879V25.2527H35.9594V20.0879H52.8605Z" />
    </svg>
  );
};

export default AAIcon;
