import { FunctionComponent } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { AmplitudeEvent, logEvent } from "common/utils/amplitude";
import { StoreStateDrawers } from "store/slices/storeStateSlice/types";
import { setDrawerOpen } from "store/slices/storeStateSlice/storeStateSlice";
import useAppDispatch from "presentation/hooks/useAppDispatch";
import ButtonFilterPopover from "presentation/components/ButtonFilterPopover";
import ChipsFilter from "presentation/components/ChipsFilter";
import useAppSelector from "../../../../../hooks/useAppSelector";
import { AsyncState, TypeDeviceInPage } from "../../../../../../store/types";

import ButtonResumenPopover from "../../../../../components/ButtonResumenPopover";
import ResumeRow from "../../ResumeRow";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SummaryPageLocale } from "../../../../../../domain/interfaces/i18n/summary.interface";

interface PageActionsProps {}

const PageActions: FunctionComponent<PageActionsProps> = () => {
  const dispatch = useAppDispatch();

  const device = useAppSelector((state) => state.commonState.device);
  const statusData = useAppSelector(
    (state) => state.storeState.kitchenState.status
  );

  const { t } = useTranslation();

  const openColumnVisibleDrawer = () => {
    logEvent(AmplitudeEvent.HiddenColumns);
    dispatch(
      setDrawerOpen({
        drawerName: StoreStateDrawers.COLUMN_VISIBILITY_DRAWER,
        isOpen: true,
      })
    );
  };

  return (
    <>
      {device !== TypeDeviceInPage.MOBIL && <ChipsFilter />}

      <Box
        sx={{
          ml: "auto",
          width: { xs: 1, lg: 3 / 6 },
          display: "flex",
          flexDirection: { xs: "row" },
          justifyContent: { xs: "center", md: "flex-end" },
          gap: { xs: 1 },
        }}
      >
        <ButtonFilterPopover />

        <Button
          color="primary"
          variant="outlined"
          sx={{
            width: { xs: "56px", md: "253px" },
            height: "40px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          onClick={openColumnVisibleDrawer}
        >
          <SettingsOutlinedIcon
            color="primary"
            sx={{
              height: "20px",
              width: "19.45px",
            }}
          />
          {device !== TypeDeviceInPage.MOBIL && (
            <Typography
              fontWeight={500}
              fontSize={"16px"}
              ml={"15px"}
              textTransform="uppercase"
            >
              {t(SummaryPageLocale.BUTTON_EDIT_COLUMNS)}
            </Typography>
          )}
        </Button>
        {device === TypeDeviceInPage.MOBIL &&
          statusData === AsyncState.SUCCESS && (
            <ButtonResumenPopover>
              <ResumeRow />
            </ButtonResumenPopover>
          )}
        {device === TypeDeviceInPage.MOBIL &&
          statusData === AsyncState.LOADING && (
            <Skeleton variant="rectangular" width="227px" height="40px" />
          )}
      </Box>
    </>
  );
};

export default PageActions;
