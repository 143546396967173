import { createSlice } from "@reduxjs/toolkit";
import { Order } from "../../domain/entities/Order";

export interface OrderActiveState {
  detailToDeliver: Array<Order>;
}

const initialState: OrderActiveState = {
  detailToDeliver: [],
};

const ordersActiveSlice = createSlice({
  name: "orderActive",
  initialState,
  reducers: {
    setDetailToDeliver: (state, action) => {
      state.detailToDeliver = action.payload;
    },
  },
});

export const { setDetailToDeliver } = ordersActiveSlice.actions;
export default ordersActiveSlice.reducer;
