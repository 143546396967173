import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MenuItem } from "@foodology-co/alejandria";
import { AsyncState } from "../types";
import { Session } from "../../domain/entities/Session";
import { MenuHttpGateway } from "../../gateways/http/menu";
import configProvider from "../../config.provider";
import { GetMenuUseCase } from "../../domain/useCases/account/getMenu";

export interface MenuState {
  open: boolean;
  items: Array<MenuItem>;
  status: AsyncState;
  error: string | null;
}

export const initialState: MenuState = {
  open: false,
  items: [],
  status: AsyncState.IDLE,
  error: null,
};

export const getUserMenu = createAsyncThunk(
  "user/getMenu",
  async (session: Session) => {
    const httpRepository = new MenuHttpGateway(configProvider);
    const getMenuUseCase = new GetMenuUseCase(httpRepository);
    const menu = await getMenuUseCase.execute(session);
    return menu;
  }
);

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.open = !state.open;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserMenu.pending, (state) => {
        state.status = AsyncState.LOADING;
      })
      .addCase(getUserMenu.fulfilled, (state, action) => {
        state.status = AsyncState.SUCCESS;
        state.items = action.payload.items;
      })
      .addCase(getUserMenu.rejected, (state, action) => {
        state.status = AsyncState.ERROR;
        state.error = action.error.message ?? null;
      });
  },
});

export const { toggleMenu } = menuSlice.actions;
export default menuSlice.reducer;
