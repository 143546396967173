import React from "react";

interface CIconProps {
  color?: string;
  width?: string;
}

const CIcon = (props: CIconProps): React.ReactElement => {
  const { color, width } = props;
  return (
    <svg
      width={!width ? "26" : width}
      height="32"
      viewBox="0 0 26 32"
      fill={!color ? "#0288D1" : color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.253 21.2478H25.6444C25.5162 23.3427 24.939 25.2024 23.9129 26.827C22.9011 28.4516 21.4832 29.7199 19.6591 30.6319C17.8493 31.544 15.6689 32 13.118 32C11.1229 32 9.33446 31.658 7.75263 30.9739C6.1708 30.2757 4.81698 29.2781 3.69117 27.9813C2.57961 26.6845 1.7317 25.1169 1.14742 23.2786C0.563136 21.4402 0.270996 19.381 0.270996 17.1009V14.9419C0.270996 12.6618 0.570261 10.6025 1.16879 8.7642C1.78157 6.9116 2.65087 5.3369 3.77667 4.04008C4.91673 2.74326 6.27768 1.74571 7.85951 1.04743C9.44134 0.349143 11.2084 0 13.1608 0C15.7544 0 17.9419 0.470274 19.7232 1.41082C21.5188 2.35137 22.9083 3.64819 23.8916 5.30127C24.8891 6.95435 25.4877 8.83545 25.6872 10.9446H19.2743C19.2031 9.69049 18.9537 8.62881 18.5262 7.75952C18.0987 6.87597 17.4502 6.21332 16.5809 5.77154C15.7259 5.31552 14.5858 5.08751 13.1608 5.08751C12.092 5.08751 11.1585 5.28702 10.3605 5.68604C9.56247 6.08506 8.89268 6.69071 8.35116 7.50301C7.80963 8.3153 7.40348 9.34135 7.13272 10.5812C6.87621 11.8067 6.74795 13.246 6.74795 14.8991V17.1009C6.74795 18.7112 6.86908 20.1291 7.11134 21.3547C7.35361 22.566 7.72412 23.5921 8.2229 24.4329C8.73593 25.2594 9.39146 25.8864 10.1895 26.314C11.0018 26.7272 11.978 26.9339 13.118 26.9339C14.4576 26.9339 15.562 26.7201 16.4313 26.2926C17.3006 25.8651 17.9633 25.2309 18.4193 24.3901C18.8896 23.5493 19.1675 22.5019 19.253 21.2478Z" />
    </svg>
  );
};

export default CIcon;
