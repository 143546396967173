import React from "react";
import config from "config.provider";
import { DrawerMenuContext } from "presentation/contexts/DrawerMenu.context";
import { KOSTopbar } from "@foodology-co/alejandria";
import useAppSelector from "../hooks/useAppSelector";

interface AppBarProps {}

const AppBar: React.FunctionComponent<AppBarProps> = () => {
  const drawerMenu = React.useContext(DrawerMenuContext);
  const user = useAppSelector((state) => state.user);
  const title = useAppSelector((state) => state.screen.title);

  return (
    <KOSTopbar
      title={title ?? ""}
      onOpenMenuClick={drawerMenu.toggle}
      isDevelopment={config.env !== "production"}
      openMenu={drawerMenu.open}
      username={user.user?.profile?.name || ""}
      avatarUrl={user.user?.profileImage || ""}
    />
  );
};

export default AppBar;
