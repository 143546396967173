import {
  GoogleMap,
  useJsApiLoader,
  useGoogleMap,
} from "@react-google-maps/api";
import { FunctionComponent, useEffect, memo, useRef } from "react";
import { Coordinate } from "../../../domain/entities/Store.entity";
import useAppSelector from "../../hooks/useAppSelector";
import configProvider from "../../../config.provider";

const containerStyle = {
  width: "100%",
  height: `calc(100vh - 56px)`,
  overflow: "hidden",
};

interface PolygonMapsProps {}

const Components = () => {
  const useMap = useGoogleMap();
  const { coverage } = useAppSelector((state) => state.mapsState);

  const addMarker = (position: Coordinate, icon: string | undefined) => {
    const marker = new window.google.maps.Marker({
      position: position,
      icon,
    });
    marker.setMap(useMap);
  };
  useEffect(() => {
    if (coverage.length > 0) {
      coverage.forEach((point) => {
        const polygon = new google.maps.Polygon({
          paths: point.coordinates,
          fillColor: "#2ecc71",
          fillOpacity: 0.2,
          strokeColor: "#27ae60",
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: false,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 100,
        });
        polygon.setMap(useMap);

        addMarker(point.kitchenCoordinate, undefined);
      });
    }
  }, [coverage, useMap]);

  return null;
};
const PolygonMap: FunctionComponent<PolygonMapsProps> = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: configProvider.maps.apiKey,
  });
  const mapRef = useRef<any>(null);
  const { coverage, version } = useAppSelector((state) => state.mapsState);

  return isLoaded ? (
    <GoogleMap
      ref={mapRef}
      key={`version-${version}`}
      mapContainerStyle={containerStyle}
      center={coverage.length > 0 ? coverage[0].kitchenCoordinate : undefined}
      zoom={14}
    >
      <Components />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default memo(PolygonMap);
