export const rows = [
  {
    id: 4,
    kitchen: "Chapinero",
    city: "Bogotá",
    country: "COL",
    totaloff: "Atención - Tipo B",
    quantityoff: "5/200",
    typeaa: "OK",
    quantityaa: "50/50",
    typea: "OK",
    quantitya: "50/50",
    typeb: "Atención",
    quantityb: "5/50",
    typec: "OK",
    quantityc: "50/50",
  },
  {
    id: 5,
    kitchen: "Usaquén",
    city: "Bogotá",
    country: "COL",
    totaloff: "OK",
    quantityoff: "3/200",
    typeaa: "OK",
    quantityaa: "50/50",
    typea: "OK",
    quantitya: "50/50",
    typeb: "OK",
    quantityb: "50/50",
    typec: "OK",
    quantityc: "50/50",
  },
  {
    id: 6,
    kitchen: "Chía",
    city: "Chía",
    country: "COL",
    totaloff: "Atención - Tipo AA",
    quantityoff: "2/200",
    typeaa: "Atención",
    quantityaa: "2/50",
    typea: "OK",
    quantitya: "50/50",
    typeb: "OK",
    quantityb: "50/50",
    typec: "OK",
    quantityc: "50/50",
  },
  {
    id: 7,
    kitchen: "Santa Monica",
    city: "Cali",
    country: "COL",
    totaloff: "OK",
    quantityoff: "3/200",
    typeaa: "OK",
    quantityaa: "50/50",
    typea: "OK",
    quantitya: "50/50",
    typeb: "OK",
    quantityb: "50/50",
    typec: "OK",
    quantityc: "50/50",
  },
  {
    id: 8,
    kitchen: "San Isidro",
    city: "Lima",
    country: "PER",
    totaloff: "Atención - Tipo C",
    quantityoff: "1/200",
    typeaa: "OK",
    quantityaa: "50/50",
    typea: "OK",
    quantitya: "50/50",
    typeb: "OK",
    quantityb: "50/50",
    typec: "Atención",
    quantityc: "1/50",
  },
  {
    id: 9,
    kitchen: "La Molina",
    city: "Lima",
    country: "PER",
    totaloff: "OK",
    quantityoff: "3/200",
    typeaa: "OK",
    quantityaa: "50/50",
    typea: "OK",
    quantitya: "50/50",
    typeb: "OK",
    quantityb: "50/50",
    typec: "OK",
    quantityc: "50/50",
  },
];
