import React, { FunctionComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Popover,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import { AmplitudeEvent, logEvent } from "common/utils/amplitude";
import { RootState } from "store/index";
import { getCityName } from "presentation/utils/city";
import FilterMultipleChoice from "presentation/components/FilterMultipleChoice";
import {
  setResultSearch,
  ISearchResult,
  ResultType,
  changeCheckedResulSearch,
  changeCheckedChildrenResulSearch,
  IKitchenItem,
} from "store/slices/filterSlice";
import ChipsFilter from "presentation/components/ChipsFilter";
import { TypeDeviceInPage } from "../../../store/types";
import useAppSelector from "../../hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { FilterLocale } from "../../../domain/interfaces/i18n/filters.interface";

interface ButtonFilterPopoverProps {}
const ButtonFilterPopover: FunctionComponent<ButtonFilterPopoverProps> = (
  props
) => {
  const {
    kitchenItems,
    citiesAll,
    kitchensAll,
    resultSearch,
    resultSearchSelected,
  } = useSelector((state: RootState) => state.filter);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const device = useAppSelector((state) => state.commonState.device);

  const [search, setSearch] = React.useState<string | null>(null);

  const handleSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: ISearchResult
  ) => {
    dispatch(changeCheckedResulSearch({ item, checked: event.target.checked }));
  };

  const handleSearchFilterChildren = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: ISearchResult,
    indexChild: number
  ) => {
    dispatch(
      changeCheckedChildrenResulSearch({
        item,
        indexChild,
        checked: event.target.checked,
      })
    );
  };

  useEffect(() => {
    if (search !== null && search.trim() !== "" && kitchenItems) {
      let resultFinal: ISearchResult[] = [];
      kitchensAll
        .filter((k) => k.name.toLowerCase().includes(search.toLowerCase()))
        .forEach((k: IKitchenItem) => {
          resultFinal.push({
            type: ResultType.KITCHEN,
            name: k.name,
            checked: resultSearchSelected.some(
              (r) => r.name === k.name && r.type === ResultType.KITCHEN
            ),
            children: [],
          });
        });

      citiesAll
        .filter((c) =>
          getCityName(c.name).toLowerCase().includes(search.toLowerCase())
        )
        .forEach((k) => {
          resultFinal.push({
            type: ResultType.CITY,
            name: k.name,
            checked: resultSearchSelected.some(
              (r) =>
                r.name === k.name &&
                r.type === ResultType.CITY &&
                r.children.filter((c) => !c.checked).length === 0
            ),
            children: kitchenItems
              .filter((kt) => kt.city === k.name)
              .map((kt) => ({
                type: ResultType.KITCHEN,
                name: kt.name,
                checked:
                  resultSearchSelected.findIndex(
                    (r) => r.name === k.name && r.type === ResultType.CITY
                  ) > -1
                    ? resultSearchSelected[
                        resultSearchSelected.findIndex(
                          (r) => r.name === k.name && r.type === ResultType.CITY
                        )
                      ].children.some(
                        (c) =>
                          kt.name === c.name &&
                          c.type === ResultType.KITCHEN &&
                          c.checked
                      )
                    : false,
                children: [],
              })),
          });
        });
      dispatch(setResultSearch(resultFinal));
    }
  }, [search]);

  useEffect(() => {
    if (resultSearchSelected.length) {
      //calculateCountFilter();
    }
  }, [resultSearchSelected]);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <Button
            color="primary"
            variant="contained"
            sx={{
              width: { xs: "96px", md: "189px" },
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingX: "16px",
              marginRight: { xs: 0, md: 3 },
            }}
            onClick={(e) => {
              logEvent(AmplitudeEvent.ClickButtonFilter);
              bindTrigger(popupState).onClick(e);
            }}
            onTouchStart={(e) => {
              logEvent(AmplitudeEvent.ClickButtonFilter);
              bindTrigger(popupState).onTouchStart(e);
            }}
          >
            <FilterListOutlinedIcon
              color="inherit"
              sx={{
                height: "20px",
                width: "19.45px",
              }}
            />
            {device !== TypeDeviceInPage.MOBIL && (
              <Typography
                fontWeight={500}
                fontSize={"16px"}
                ml={"15px"}
                textTransform="uppercase"
              >
                {t(FilterLocale.BUTTON)}
              </Typography>
            )}

            {resultSearchSelected.length > 0 && (
              <Avatar
                sx={{
                  bgcolor: "#ffffff",
                  width: 24,
                  height: 24,
                  marginLeft: { xs: 1, md: 2 },
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="400"
                  fontSize="14px"
                  color="primary"
                >
                  {resultSearchSelected.length}
                </Typography>
              </Avatar>
            )}
          </Button>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              marginTop: 1,
            }}
          >
            <Box
              display="flex"
              flexDirection={"column"}
              width={328}
              gap={1}
              paddingBottom={2}
              sx={{ padding: "24px" }}
            >
              <Typography fontSize={20} fontWeight={600} variant="h6">
                {t(FilterLocale.TITLE)}
              </Typography>

              <ChipsFilter />

              <TextField
                value={search ?? ""}
                onChange={(e) =>
                  setSearch(
                    e.target.value.trim() === "" ? null : e.target.value
                  )
                }
                placeholder={t(FilterLocale.SEARCH_TEXT).toString()}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />

              <Box display="flex" flexDirection="column" paddingLeft={1.5}>
                {resultSearch &&
                  resultSearch.map((item: ISearchResult) => (
                    <>
                      <FormControlLabel
                        sx={{ fontSize: "14px" }}
                        label={
                          item.type === ResultType.CITY ? (
                            getCityName(item.name)
                          ) : (
                            <Typography textTransform="capitalize">
                              {item.name}
                            </Typography>
                          )
                        }
                        control={
                          <Checkbox
                            checked={item.checked}
                            onChange={(e) => handleSearchFilter(e, item)}
                          />
                        }
                      />
                      {item.children && item.children.length > 0 && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          paddingLeft={1.5}
                        >
                          {item.children.map(
                            (child: ISearchResult, indexChildren) => (
                              <FormControlLabel
                                sx={{ fontSize: "14px" }}
                                label={
                                  <Typography textTransform="capitalize">
                                    {child.name}
                                  </Typography>
                                }
                                control={
                                  <Checkbox
                                    checked={child.checked}
                                    onChange={(e) =>
                                      handleSearchFilterChildren(
                                        e,
                                        item,
                                        indexChildren
                                      )
                                    }
                                  />
                                }
                              />
                            )
                          )}
                        </Box>
                      )}
                    </>
                  ))}
              </Box>

              <FilterMultipleChoice />
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
export default ButtonFilterPopover;
