import { ConfigProvider } from "../../config.provider";
import { Session } from "../../domain/entities/Session";
import { User } from "../../domain/entities/User";
import { CanGetUser } from "../../domain/interfaces/account/canGetUser";

type UserResponse = {
  _id: string;
  username: string;
  emails: Array<{
    address: string;
  }>;
  profile: {
    firstName: string;
    lastName: string;
    name: string;
    position: string;
    identification: string;
    scope: string;
    country: string;
    kitchen: { kitchenId: string };
  };
  rol: string;
  profileImage: string;
};

export class UserHTTPGateway implements CanGetUser {
  constructor(private readonly config: ConfigProvider) {}
  public async getUser(session: Session): Promise<User> {
    const userResponse = await fetch(
      `${this.config.kitchenDisplay.url}/api/userInfo`,
      {
        method: "GET",
        headers: {
          "x-user-id": session.userId,
          "x-auth-token": session.token,
        },
      }
    );

    const userJson: UserResponse = await userResponse.json();

    const user: User = {
      _id: userJson._id,
      profile: userJson.profile,
      rolName: userJson.rol,
      profileImage: userJson.profileImage,
    };

    return user;
  }
}
