import React from "react";
import { Box } from "@mui/material";
import PageHeader from "../../components/PageHeader";
import { SuppliesSummaryPageLocale } from "../../../domain/interfaces/i18n/suppliesSummary.interface";
import { useTranslation } from "react-i18next";
import PageActions from "../summary/components/StoreState/PageActions/PageActions";
import ResumeRow from "./components/ResumeRow";
import DataTable from "./components/DataTable/DataTable";

const SuppliesSummary = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ px: { xs: 2, md: 4 } }}
      display="flex"
      gap={2}
      flexDirection="column"
      height="100%"
    >
      <PageHeader
        title={t(SuppliesSummaryPageLocale.TITLE)}
        subtitle={t(SuppliesSummaryPageLocale.DESCRIPTION) ?? ""}
      >
        <PageActions />
      </PageHeader>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        sx={{ width: { xs: 1 } }}
      >
        <ResumeRow />
      </Box>
      <DataTable />
    </Box>
  );
};

export default SuppliesSummary;
