import { Menu } from "../../domain/entities/Menu";
import { Session } from "../../domain/entities/Session";
import { CanGetMenu } from "../../domain/interfaces/account/canGetMenu";
import { ConfigProvider } from "../../config.provider";

type MenuResponse = Array<{
  _id: string;
  icon: string;
  module: string;
  navigateTo: string;
  roles: string[];
  title: string;
  shortTitle: string;
  navigateEquals: null;
}>;

export class MenuHttpGateway implements CanGetMenu {
  constructor(private readonly config: ConfigProvider) {}
  public async getMenu(session: Session): Promise<Menu> {
    const userResponse = await fetch(
      `${this.config.kitchenDisplay.url}/api/menuByUser/kitchen-monitor`,
      {
        method: "GET",
        headers: {
          "x-user-id": session.userId,
          "x-auth-token": session.token,
        },
      }
    );

    const menuJson: MenuResponse = await userResponse.json();

    return { items: menuJson };
  }
}
