import Brand from "../../../domain/entities/Brand.entity";
import Kitchen from "../../../domain/entities/Kitchen.enitity";
import { Platform, StatePlatform } from "../../../domain/entities/Store.entity";
import OutOfTimeProcess from "../../../domain/useCases/kitchen/OutOfTime.process";
import { EventUpdateBase } from "../../types";

export const mappedBrandState = (
  data: Array<Kitchen>,
  kitchenIndex: number,
  kitchenBrandIndex: number,
  event: EventUpdateBase
): Array<Brand> => {
  const kitchenProcessImpl = new OutOfTimeProcess();
  return data[kitchenIndex].brands.map((brand, brandIndex) => {
    if (brandIndex !== kitchenBrandIndex) return brand;
    let tempBranState = JSON.parse(
      JSON.stringify(brand.state[event.platform as Platform])
    );

    kitchenProcessImpl.executePlatform(
      tempBranState,
      event.platform as Platform
    );

    if (
      event.state != null &&
      event.platform != null &&
      event.universalId != null &&
      event.lastUpdate != null
    ) {
      return {
        ...brand,
        state: {
          ...brand.state,
          [event.platform]: {
            ...tempBranState,
            enabled: event.status.platform === StatePlatform.OPEN,
            message: "",
            lastUpdate: new Date(event.lastUpdate),
            reasonState: event.status.reason,
          },
        },
      };
    } else if (
      event.state != null &&
      event.enabled != null &&
      event.platform != null &&
      event.universalId != null &&
      event.lastUpdate != null
    ) {
      return {
        ...brand,
        state: {
          ...brand.state,
          [event.platform]: {
            ...tempBranState,
            enabled: event.status.platform === StatePlatform.OPEN,
            //revisar si este filtro aplica o esta mas adelante
            visible: event.status.platform === StatePlatform.OPEN,
            message: "",
            lastUpdate: new Date(event.lastUpdate),
          },
        },
      };
    } else {
      return {
        ...brand,
        state: {
          ...brand.state,
          [event.platform]: {
            ...tempBranState,
            coverage:
              event.coverage != null ? JSON.parse(event.coverage) : null,
            lastUpdate: new Date(event.lastUpdate),
          },
        },
      };
    }
  });
};
