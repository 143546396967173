import useNPSEmbed from "../../hooks/useNPSEmbed";
import { useEffect } from "react";
const FormNPS = () => {
  const { successCallback } = useNPSEmbed("");
  useEffect(() => {
    successCallback();
  }, []);
  return <></>;
};

export default FormNPS;
