import {
  BrandState,
  BrandStatusOutTimeType,
} from "../../entities/Brand.entity";
import Kitchen from "../../entities/Kitchen.enitity";
import { Platform } from "../../entities/Store.entity";
import { KitchenProcessStrategy } from "../../interfaces/strategy/kitchen.strategy";

class OutOfTimeProcess implements KitchenProcessStrategy {
  DAYS = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  public executePlatform(state: BrandState | null, platform: Platform) {
    if (!state) return;
    try {
      const scheduleMap: any = state.schedule;
      const dateNow = new Date();
      let timeZone = scheduleMap.timeZone;
      const dateObject = new Date(dateNow).toLocaleString("en-US", {
        timeZone,
      });
      const dateNowFormatUTC = new Date(dateObject);

      const currentNumberDay = dateNowFormatUTC.getDay();
      if (scheduleMap.week && scheduleMap.week[this.DAYS[currentNumberDay]]) {
        const schedules = scheduleMap.week[this.DAYS[currentNumberDay]];

        schedules.map((schedule: any) => {
          const dateStartEnd = schedule.split("-");
          const dateStart = new Date(
            `${dateNowFormatUTC.getFullYear()}-${
              dateNowFormatUTC.getMonth() + 1
            }-${dateNowFormatUTC.getDate()} ${dateStartEnd[0]}`
          );
          const dateEnd = new Date(
            `${dateNowFormatUTC.getFullYear()}-${
              dateNowFormatUTC.getMonth() + 1
            }-${dateNowFormatUTC.getDate()} ${dateStartEnd[1]}`
          );
          if (
            (dateNowFormatUTC < dateStart || dateNowFormatUTC > dateEnd) &&
            state?.enabled
          ) {
            if (
              platform === Platform.RAPPI ||
              platform === Platform.DIDI ||
              platform === Platform.IFOOD ||
              platform === Platform.UBER ||
              platform === Platform.PEDIDOSYA
            ) {
              state.status_atention = BrandStatusOutTimeType.OFF_OK;
            } else {
              state.status_atention = BrandStatusOutTimeType.ON_ATENTION;
            }
          } else if (
            dateNowFormatUTC >= dateStart &&
            dateNowFormatUTC <= dateEnd &&
            !state?.enabled
          ) {
            state.status_atention = BrandStatusOutTimeType.OFF_ATENTION;
          } else {
            state.status_atention = state.enabled
              ? BrandStatusOutTimeType.ON_OK
              : BrandStatusOutTimeType.OFF_OK;
          }
          state.label_atention = schedule;
        });
      } else {
        state.status_atention = BrandStatusOutTimeType.NO_VALIDATE;
      }
    } catch (e) {
      console.error(e);
    }
  }

  private isAtentionStatus(
    status: BrandStatusOutTimeType | null | undefined
  ): boolean {
    if (status === null || status === undefined) return false;
    return (
      status === BrandStatusOutTimeType.OFF_ATENTION ||
      status === BrandStatusOutTimeType.ON_ATENTION
    );
  }

  execute(data: Kitchen[]): Kitchen[] {
    return data.map((kitchen) => {
      const brands = kitchen.brands.map((brand) => {
        const platforms: any = [];
        Object.keys(brand.state).forEach((platform) => {
          const state = brand.state[platform as Platform];
          if (state && state?.schedule) {
            this.executePlatform(state, platform as Platform);
          } else if (state) {
            state.status_atention = BrandStatusOutTimeType.NO_VALIDATE;
          }

          platforms[platform] = state;
        });
        return {
          id: brand.id,
          name: brand.name,
          state: platforms,
        };
      });

      const totalAtention = brands.reduce(
        (sum, brand) =>
          sum +
          Object.keys(brand.state).reduce(
            (count, key) =>
              this.isAtentionStatus(
                brand.state[key as Platform]?.status_atention
              )
                ? count + 1
                : count,
            0
          ),
        0
      );

      return {
        brands: brands,
        city: kitchen.city,
        country: kitchen.country,
        activeOrders: kitchen.activeOrders,
        protocols: kitchen.protocols,
        id: kitchen.id,
        name: kitchen.name,
        peopleActive: kitchen.peopleActive,
        kitchenSetup: kitchen.kitchenSetup,
        type: kitchen.type,
        countAtention: totalAtention,
      };
    });
  }
}

export default OutOfTimeProcess;
