import { QueryOrderActive } from "domain/entities/Order";
import OrderActiveGateway from "domain/interfaces/gateways/orderActive.gateway";
import RealmClientImpl from "../realmClient";

class RealmOrderActiveGateway implements OrderActiveGateway {
  private client: RealmClientImpl | null = null;
  private cli: globalThis.Realm.Services.MongoDB | null = null;

  async connectClient(): Promise<any> {
    if (!this.client) {
      this.client = new RealmClientImpl();
      await this.client.connect();
      this.cli = this.client.getClient();
    }
  }

  async getOrderActive(kitchenId: string): Promise<QueryOrderActive[] | null> {
    if (!this.client) return null;
    if (!this.cli) return null;
    return await this.cli
      .db("kitchen")
      .collection<QueryOrderActive>("order")
      .aggregate([
        {
          $match: {
            "kitchen.id": kitchenId,
            state: {
              $in: [
                "queued",
                "cooking",
                "returned_to_station",
                "packing",
                "waiting_for_delivery",
              ],
            },
          },
        },
        {
          $project: {
            _id: 1,
            platform: 1,
            station: "$displayInfo.station.name",
            cart: "$cart.items",
            brand: 1,
            cartExploited: "$cartExploited.items",
          },
        },
      ]);
  }
}

export default RealmOrderActiveGateway;
