import { Supply } from "../../../domain/entities/Supplies.entity";
import { AsyncState } from "../../types";

export enum SupplyStateDrawers {
  SUPPLIES = "supplyDrawerKitchen",
}

export interface SupplyState {
  drawers: {
    [SupplyStateDrawers.SUPPLIES]: {
      open: boolean;
      title: string;
      kitchen: string;
      status: AsyncState;
      data: Supply[];
    };
  };
}
export const supplies: Supply[] = [
  {
    id: 4,
    kitchen: "Chapinero",
    country: "COL",
    city: "Bogotá",
    suppliesOff: [
      {
        sku: 160,
        supply: "Arroz",
        type: "B",
        inventory: "5 UND",
      },
    ],
  },
  {
    id: 5,
    kitchen: "Usaquén",
    country: "COL",
    city: "Bogotá",
    suppliesOff: [
      {
        sku: 170,
        supply: "Azúcar",
        type: "AA",
        inventory: "3 UND",
      },
    ],
  },
  {
    id: 6,
    kitchen: "Chía",
    country: "COL",
    city: "Chía",
    suppliesOff: [
      {
        sku: 180,
        supply: "Huevos",
        type: "A",
        inventory: "10 UND",
      },
    ],
  },
  {
    id: 7,
    kitchen: "Santa Monica",
    country: "COL",
    city: "Cali",
    suppliesOff: [
      {
        sku: 190,
        supply: "Sal",
        type: "C",
        inventory: "2 UND",
      },
    ],
  },
  {
    id: 8,
    kitchen: "San Isidro",
    country: "COL",
    city: "Lima",
    suppliesOff: [
      {
        sku: 200,
        supply: "Aceite",
        type: "B",
        inventory: "1 UND",
      },
    ],
  },
  {
    id: 9,
    kitchen: "La Molina",
    country: "PER",
    city: "Lima",
    suppliesOff: [
      {
        sku: 210,
        supply: "Café",
        type: "AA",
        inventory: "2 UND",
      },
    ],
  },
];
