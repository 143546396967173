import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../../types";
import { OrderMapToUpdate, OrderState, OrderStateDrawers } from "./types";
import { getOrderActiveState, getKitchensStateOrder } from "./orderStateThunks";
import { Platform, StatusStore } from "../../../domain/entities/Store.entity";
import Kitchen from "../../../domain/entities/Kitchen.enitity";
import { mappedActiveOrders, mappedPeopleActiveOrder } from "./helpers";
import Brand from "../../../domain/entities/Brand.entity";
import { mappedBrandState } from "../storeStateSlice/helpers";
import { Schedule } from "../../../gateways/amplify/types";

const initialState: OrderState = {
  drawers: {
    [OrderStateDrawers.ORDERS_ACTIVE]: {
      open: false,
      kitchenId: "",
      status: AsyncState.IDLE,
      data: [],
    },
  },
  kitchenState: {
    baseData: null,
    filteredData: null,
    status: AsyncState.IDLE,
  },
};
const dataTableSlice = createSlice({
  name: "orders-state",
  initialState,
  reducers: {
    clearStoresOrder: (state, action) => {
      state.kitchenState.baseData = null;
      state.kitchenState.filteredData = null;
    },
    setDrawerOpenOrder: (
      state,
      action: PayloadAction<{
        drawerName: OrderStateDrawers;
        isOpen: boolean;
        kitchenId: string;
      }>
    ) => {
      const { drawerName, isOpen, kitchenId } = action.payload;
      state.drawers[drawerName].open = isOpen;
      state.drawers[drawerName].kitchenId = kitchenId;
    },
    setFilteredData: (state, action) => {
      state.kitchenState.filteredData = action.payload;
    },
    updateKitchenStateBaseData: (state, action) => {
      const event: {
        lastUpdate: string;
        platform: Platform;
        state: string;
        type: string;
        universalId: string;
        enabled: boolean;
        kitchenId: string;
        coverage: string;
        schedule: Schedule;
        peopleActive?: number;
        activeOrders?: string | null;
        reasonState?: string | null;
        status: StatusStore;
      } = action.payload.event;

      const typeUpdate: OrderMapToUpdate = action.payload.type;

      const baseData: Array<Kitchen> | null = current(
        state.kitchenState.baseData
      );

      const filteredData: Array<Kitchen> | null = current(
        state.kitchenState.filteredData
      );

      if (
        state.kitchenState.filteredData === null ||
        filteredData === null ||
        baseData === null
      ) {
        return;
      }

      let kitchenIndex = -1;
      let kitchenBrandIndex = -1;

      let kitchen: Kitchen | null = null;
      let brands: Brand[] = [];

      let brandsBase: Brand[] = [];
      let kitchenBase: Kitchen | null = null;

      switch (typeUpdate) {
        case OrderMapToUpdate.BASE_UPDATE:
          kitchenIndex = filteredData?.findIndex((kitchen) =>
            kitchen.brands.some(
              (brand) =>
                event.universalId === brand.state[event.platform]?.storeId
            )
          );
          kitchenBrandIndex = filteredData[kitchenIndex].brands.findIndex(
            (brand) =>
              event.universalId === brand.state[event.platform]?.storeId
          );
          brands = mappedBrandState(
            filteredData,
            kitchenIndex,
            kitchenBrandIndex,
            event
          );

          kitchen = { ...filteredData[kitchenIndex], brands: brands };

          break;
        case OrderMapToUpdate.PEOPLE_ACTIVE:
          kitchenIndex = filteredData?.findIndex(
            (kitchen) => kitchen.id === event.kitchenId
          );
          kitchen = mappedPeopleActiveOrder(filteredData, kitchenIndex, event);
          break;
        case OrderMapToUpdate.ACTIVE_ORDERS:
          kitchenIndex = filteredData?.findIndex(
            (kitchen) => kitchen.id === event.kitchenId
          );
          kitchen = mappedActiveOrders(filteredData, kitchenIndex, event);
          break;
      }

      state.kitchenState.filteredData = state.kitchenState.filteredData.map(
        (_, index) => {
          if (index === kitchenIndex && kitchen != null) return kitchen;
          return _;
        }
      );

      //update Base
      if (state.kitchenState.baseData === null) return;

      let kitchenIndexBase = -1;
      let kitchenBrandIndexBase = -1;

      switch (typeUpdate) {
        case OrderMapToUpdate.BASE_UPDATE:
          kitchenIndexBase = baseData?.findIndex((kitchen) =>
            kitchen.brands.some(
              (brand) =>
                event.universalId === brand.state[event.platform]?.storeId
            )
          );

          kitchenBrandIndexBase = baseData[kitchenIndexBase].brands.findIndex(
            (brand) =>
              event.universalId === brand.state[event.platform]?.storeId
          );
          brandsBase = mappedBrandState(
            baseData,
            kitchenIndexBase,
            kitchenBrandIndexBase,
            event
          );
          kitchenBase = { ...baseData[kitchenIndexBase], brands: brandsBase };
          break;
        case OrderMapToUpdate.PEOPLE_ACTIVE:
          kitchenIndexBase = baseData?.findIndex(
            (kitchen) => kitchen.id === event.kitchenId
          );
          kitchenBase = mappedPeopleActiveOrder(
            baseData,
            kitchenIndexBase,
            event
          );
          break;
        case OrderMapToUpdate.ACTIVE_ORDERS:
          kitchenIndexBase = baseData?.findIndex(
            (kitchen) => kitchen.id === event.kitchenId
          );
          kitchenBase = mappedActiveOrders(filteredData, kitchenIndex, event);
          break;
      }

      state.kitchenState.baseData = state.kitchenState.baseData.map(
        (_, index) => {
          if (index === kitchenIndexBase && kitchenBase != null)
            return kitchenBase;
          return _;
        }
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderActiveState.pending, (state, action) => {
      state.drawers[OrderStateDrawers.ORDERS_ACTIVE].status =
        AsyncState.LOADING;
    });
    builder.addCase(getOrderActiveState.rejected, (state, action) => {
      state.drawers[OrderStateDrawers.ORDERS_ACTIVE].status = AsyncState.ERROR;
    });
    builder.addCase(getOrderActiveState.fulfilled, (state, action) => {
      state.drawers[OrderStateDrawers.ORDERS_ACTIVE].status =
        AsyncState.SUCCESS;
      state.drawers[OrderStateDrawers.ORDERS_ACTIVE].data = action.payload;
    });

    builder.addCase(getKitchensStateOrder.pending, (state, action) => {
      state.kitchenState.status = AsyncState.LOADING;
    });
    builder.addCase(getKitchensStateOrder.rejected, (state, action) => {
      state.kitchenState.status = AsyncState.ERROR;
    });
    builder.addCase(getKitchensStateOrder.fulfilled, (state, action) => {
      state.kitchenState.status = AsyncState.SUCCESS;
      state.kitchenState.baseData = action.payload;
      state.kitchenState.filteredData = action.payload;
    });
  },
});

export const {
  setDrawerOpenOrder,
  clearStoresOrder,
  setFilteredData,
  updateKitchenStateBaseData,
} = dataTableSlice.actions;
export default dataTableSlice.reducer;
