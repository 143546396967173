import { KitchenSetup, Schedule } from "../../gateways/amplify/types";
import { OrderActive, Protocol } from "./Protocol.entity";

export enum Platform {
  RAPPI = "rappi",
  IFOOD = "ifood",
  UBER = "uber",
  DIDI = "didi",
  PEDIDOSYA = "pedidosya",
}

export enum StateInternal {
  NOT_LAUNCHED = "NOT_LAUNCHED",
  OPEN = "OPEN",
  TEMP_CLOSED = "TEMP_CLOSED",
  PERM_CLOSED = "PERM_CLOSED",
}

export enum StatePlatform {
  OPEN = "OPEN",
  CLOSED = "CLOSE",
}

export enum StateReason {
  OPERATING_CORRECTLY = "FOODOLOGY: Operando correctamente",
  STAFF_SHORTAGE = "FOODOLOGY: Falta de personal",
  KITCHEN_OPENING_ISSUES = "FOODOLOGY: Problemas en apertura de cocina",
  GAS_FAILURES = "FOODOLOGY: Fallas de gas",
  INTERNET_FAILURES = "FOODOLOGY: Fallas de internet",
  POWER_FAILURES = "FOODOLOGY: Fallas de energía",
  WATER_FAILURES = "FOODOLOGY: Fallas de agua",
  STOCKOUTS = "FOODOLOGY: Stockouts",
  SALES_OVERFLOW = "FOODOLOGY: Exceso de ventas",
  SPECIAL_EVENTS = "FOODOLOGY: Eventos especiales",
  SCHEDULED_MAINTENANCE = "FOODOLOGY: Mantenimiento programado",
  RELOCATION = "FOODOLOGY: Mudanza o traslado",
  STAFF_TRAINING = "FOODOLOGY: Capacitación del personal",
  OUT_OF_SERVICE_BRAND = "FOODOLOGY: Marca fuera de servicio",
  INTEGRATION_LACK = "FOODOLOGY: Falta de integración",
  WITHOUT_REASON = "FOODOLOGY: Sin razón",
  CLOSED_BY_PLATFORM = "PLATAFORMA: Cerrado por plataforma",
}

export type Coordinate = {
  lat: number;
  lng: number;
};
export type CoveragePolygon = {
  area: number;
  unit: string;
  kitchenCoordinate: Coordinate;
  coordinatesEvaluated: number;
  coordinates: Array<Coordinate>;
  lastUpdate: string;
  version: number;
};

export type PlatformStore = {
  id?: string | null;
  name: Platform;
  enabled: boolean | null;
  visible: boolean | null;
  lastUpdate: Date | null;
  schedule?: Schedule | null;
  coverage: CoveragePolygon | null;
  reasonState?: string | null;
  status?: StatusStore;
  platformConfig: PlatformConfig | null;
};

export type Platforms = {
  [platformName in Platform]: PlatformStore | null;
};

export interface PlatformConfig {
  isMarketPlace: boolean;
  isParentStore: boolean;
  theoreticalCoverage: {
    area: number;
    unit: string;
  };
}

export enum TypeResultValidation {
  AVAILABLE = "AVAILABLE",
  NOT_EXIST = "NOT_EXIST",
  DISCOUNT_DIFFERENCE = "DISCOUNT_DIFFERENCE",
}
export interface Campaign {
  storeId: string;
  campaignId: number;
  campaignName: string;
  theoreticalDiscountedProducts: number;
  discountedProducts: number;
  products: Array<{
    id: string;
    name: string;
    discounted: number;
    theoreticalDiscounted: number;
    typeResultValidation: TypeResultValidation;
  }>;
}

export interface StoreCampaing {
  universalId: string;
  brandId: string;
  campaigns: {
    campaigns: Array<Campaign>;
    storeId: string;
  } | null;
  country: string;
  kitchenId: string;
}
export interface StatusStore {
  internal: StateInternal;
  lastUpdate: string;
  platform: StatePlatform;
  reason: StatePlatform;
}

interface Store {
  id: string | null;
  country: string;
  city: string | null;
  brandId: string | null;
  brandName: string;
  kitchenId: string | null;
  kitchenName: string;
  kitchenActiveOrders: OrderActive | null;
  protocols: Protocol[];
  kitchenSetup: Array<KitchenSetup>;
  platforms: Platforms;
  peopleActive: number;
  type: string | null;
  status?: StatusStore;
}

export default Store;
