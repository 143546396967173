// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import configProvider from "../../config.provider";

const awsmobile = {
  aws_appsync_graphqlEndpoint: configProvider.appsync.url,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: configProvider.appsync.apiKey,
};

export default awsmobile;
