export const getCityName = (codename: string): string => {
  const cities: { [codename: string]: string } = {
    BAQ: "Barranquilla",
    CLO: "Cali",
    BOG: "Bogotá",
    GDL: "Guadalajara",
    SMR: "Santa Marta",
    LIM: "Lima",
    ZCMX: "Zona Centro",
    PBC: "Puebla",
    PEI: "Pereira",
    MID: "Merida",
    SAO: "São Paulo",
    MTY: "Monterrey",
    CDMX: "Ciudad de México",
    CTG: "Cartagena",
    MDE: "Medellín",
    BGA: "Bucaramanga",
    SD: "Santo Domingo",
  };

  if (!Object.keys(cities).includes(codename)) {
    return codename;
  }

  return cities[codename];
};

export const getCities = () => {
  return [
    { code: "BAQ", name: "Barranquilla" },
    { code: "CLO", name: "Cali" },
    { code: "BOG", name: "Bogotá" },
    { code: "GDL", name: "Guadalajara" },
    { code: "SMR", name: "Santa Marta" },
    { code: "LIM", name: "Lima" },
    { code: "ZCMX", name: "Zona Centro" },
    { code: "PBC", name: "Puebla" },
    { code: "PEI", name: "Pereira" },
    { code: "MID", name: "Merida" },
    { code: "SAO", name: "São Paulo" },
    { code: "MTY", name: "Monterrey" },
    { code: "CDMX", name: "Ciudad de México" },
    { code: "CTG", name: "Cartagena" },
    { code: "MDE", name: "Medellín" },
    { code: "BGA", name: "Bucaramanga" },
    { code: "SD", name: "Santo Domingo" },
  ];
};
