import { createSlice } from "@reduxjs/toolkit";
import { TypeDeviceInPage, TypeScope } from "../types";

export interface CommonState {
  device: TypeDeviceInPage;
  scope: TypeScope;
}
const initialState: CommonState = {
  device: TypeDeviceInPage.UNKNOW,
  scope: TypeScope.ALL,
};
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setScope: (state, action) => {
      state.scope = action.payload;
    },
  },
});

export const { setDevice, setScope } = commonSlice.actions;
export default commonSlice.reducer;
