import { Avatar, Box, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";

interface SuppliesOffDetailsHeaderProps {
  titleHeader: string;
  bgcolor: string;
  children: React.ReactNode;
  count: number;
}

const SuppliesOffDetailsHeader: FunctionComponent<
  SuppliesOffDetailsHeaderProps
> = (props) => {
  const { titleHeader, children, bgcolor, count } = props;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      my={1}
      sx={{
        bgcolor,
        position: "sticky",
        top: 174,
        zIndex: 1201,
        padding: "5px 20px",
      }}
    >
      <Box display="flex" alignItems="center">
        {children}
        <Typography
          variant="h6"
          color="white"
          lineHeight="20px"
          fontWeight={600}
          fontSize={16}
          marginLeft={1}
        >
          {titleHeader}
        </Typography>
      </Box>
      <Avatar sx={{ bgcolor: "white" }}>
        <Typography variant="h6" color={bgcolor}>
          {count}
        </Typography>
      </Avatar>
    </Box>
  );
};

export default SuppliesOffDetailsHeader;
