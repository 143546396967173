import Kitchen from "../domain/entities/Kitchen.enitity";
import AmplifyKitchenGateway from "gateways/amplify/gateways/kitchen.gateway";
import GetKitchensStateUseCaseImpl from "domain/useCases/kitchen/getKitchensState.usecase";
import OrderKitchenProcess from "domain/useCases/kitchen/OrderKitchen.process";
import OrderKitchenByAtentionUseCase from "domain/useCases/kitchen/OrderKitchenByAtention.usecase";
import OutOfTimeProcess from "domain/useCases/kitchen/OutOfTime.process";
import KitchenProcessImpl from "domain/useCases/kitchen/kitchenProcess.usecase";

const getKitchenState = async (): Promise<Array<Kitchen>> => {
  const gateway = new AmplifyKitchenGateway();
  const getKitchensStateUseCase = new GetKitchensStateUseCaseImpl(gateway);
  const kitchensState = await getKitchensStateUseCase.exec();

  const kitchenProcessImpl = new KitchenProcessImpl(new OrderKitchenProcess());

  const dataProcess = kitchenProcessImpl.execute(kitchensState);

  kitchenProcessImpl.setStrategy(new OutOfTimeProcess());
  const outOfTimeDataProcess = kitchenProcessImpl.execute(dataProcess);

  kitchenProcessImpl.setStrategy(new OrderKitchenByAtentionUseCase());
  const orderData = kitchenProcessImpl.execute(outOfTimeDataProcess);

  return orderData;
};

export { getKitchenState };
