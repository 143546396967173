import {
  ItemCategory,
  OrderActiveGroupDetail,
  OrderActiveGroupDrawer,
  QueryOrderActive,
} from "../../entities/Order";
import OrderActiveGateway from "../../interfaces/gateways/orderActive.gateway";

interface GetOrderActiveGroup {
  exec(kitchenId: string): Promise<Array<OrderActiveGroupDrawer>>;
}
class GetOrderActiveGroupImpl implements GetOrderActiveGroup {
  private gateway;
  constructor(gateway: OrderActiveGateway) {
    this.gateway = gateway;
  }

  groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store: any, item) => {
      let key = grouper(item);
      if (!store.has(key)) {
        store.set(key, [item]);
      } else {
        store.get(key).push(item);
      }
      return store;
    }, new Map<K, V[]>());
  }

  mapToObj<T>(m: Map<string, T>): { [key: string]: T } {
    return Array.from(m).reduce((obj: any, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
  }

  async exec(kitchenId: string): Promise<OrderActiveGroupDrawer[]> {
    await this.gateway.connectClient();
    const d = this.gateway.getOrderActive(kitchenId);
    let process = await d;

    let groupItemDrawer: Array<OrderActiveGroupDrawer> = [];

    const process2 = this.groupBy(process || [], (x) => x.station);

    process2.forEach((items: any, key: string) => {
      let groupItem: OrderActiveGroupDrawer = {
        station: key,
        totalOrders: (process || []).length,
        details: [],
      };
      const platforms = this.groupBy(items, (x: any) => x.platform);
      platforms.forEach((itemsPlat: any, keyPlat: string) => {
        const brandGroup = this.groupBy(itemsPlat, (x: any) => x.brand.id);
        brandGroup.forEach(
          (itemsBrand: QueryOrderActive[], keyBrand: string) => {
            let groupItemDetail: OrderActiveGroupDetail = {
              id: `${key}-${keyPlat}-${keyBrand}`,
              platform: keyPlat,
              brand: keyBrand,
              total: itemsBrand.length,
              strong: 0,
            };
            groupItemDetail.strong = itemsBrand.reduce((prev, order) => {
              return (
                prev +
                (order.cartExploited ? order.cartExploited : order.cart).reduce(
                  (p, c) =>
                    p + (c.category === ItemCategory.MAIN_COURSE ? 1 : 0),
                  0
                )
              );
            }, 0);

            groupItem.details.push(groupItemDetail);
          }
        );
      });
      groupItemDrawer.push(groupItem);
    });

    return groupItemDrawer;
  }
}
export default GetOrderActiveGroupImpl;
