import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";

import { Platform } from "domain/entities/Store.entity";
import { BrandStatusOutTimeType } from "../../../../domain/entities/Brand.entity";
import useAppSelector from "../../../hooks/useAppSelector";
import { AsyncState } from "../../../../store/types";
import { useTranslation } from "react-i18next";
import { SummaryPageLocale } from "../../../../domain/interfaces/i18n/summary.interface";

interface Resume {
  [platform: string]: {
    total: number;
    enabled: number;
    disabled: number;
  };
}

interface ResumeRowProps {}

const ResumeRow = (props: ResumeRowProps) => {
  const [resumeData, setResumeData] = React.useState<Resume | null>();
  const { t } = useTranslation();

  const kitchensState = useAppSelector(
    (state) => state.storeState.kitchenState.filteredData
  );
  const kitchensStateStatus = useAppSelector(
    (state) => state.storeState.kitchenState.status
  );

  React.useEffect(() => {
    if (kitchensState) {
      const resume: Resume = {};

      kitchensState.forEach((kitchen) =>
        kitchen.brands.forEach((brand: any) =>
          Object.keys(brand.state).forEach((platform) => {
            if (!resume[platform]) {
              resume[platform] = {
                total: 0,
                enabled: 0,
                disabled: 0,
              };
            }

            if (brand.state[platform as Platform] === null) {
              return;
            }

            resume[platform].total = resume[platform].total + 1;
            if (
              brand.state[platform as Platform]?.status_atention ===
                BrandStatusOutTimeType.NO_VALIDATE ||
              brand.state[platform as Platform]?.status_atention ===
                BrandStatusOutTimeType.ON_OK ||
              brand.state[platform as Platform]?.status_atention ===
                BrandStatusOutTimeType.OFF_OK ||
              brand.state[platform as Platform]?.status_atention ===
                BrandStatusOutTimeType.FALSE_ALARM
            ) {
              resume[platform].enabled = resume[platform].enabled + 1;
            } else {
              resume[platform].disabled = resume[platform].disabled + 1;
            }
          })
        )
      );

      setResumeData(resume);
    }
  }, [kitchensState]);

  return (
    <Box display="flex" alignItems="center" overflow="auto">
      <Box
        display="flex"
        alignItems="center"
        overflow="auto"
        flexGrow={1}
        sx={{
          flexDirection: { xs: "row" },
          flexWrap: { xs: "wrap" },
        }}
      >
        {kitchensStateStatus === AsyncState.LOADING ? (
          <Box display="flex" flexDirection="row" width="100%">
            <Skeleton
              variant="rectangular"
              width="20%"
              height={70}
              sx={{ marginLeft: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              width="20%"
              height={70}
              sx={{ marginLeft: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              width="20%"
              height={70}
              sx={{ marginLeft: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              width="20%"
              height={70}
              sx={{ marginLeft: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              width="20%"
              height={70}
              sx={{ marginLeft: "5px" }}
            />
          </Box>
        ) : (
          resumeData &&
          Object.keys(resumeData)?.map((platform, i) => (
            <Fragment key={`${platform}-${i}`}>
              {resumeData[platform].total > 0 && (
                <Box
                  borderRadius={1}
                  bgcolor="white"
                  display="flex"
                  flex={1}
                  gap={2}
                  py={2}
                  px={2}
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid #F1EFEF"
                  sx={{
                    ml: { md: "5px" },
                    width: "50%",
                  }}
                >
                  <Box display="flex" gap={0.5}>
                    <Avatar
                      src={`https://images-kos.s3.amazonaws.com/logo_delivery/logo-${platform}.jpeg`}
                      sx={{ width: 32, height: 32 }}
                    />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100px"
                    gap={0.5}
                  >
                    {resumeData[platform].disabled > 0 && (
                      <Typography variant="body2" color="text.secondary">
                        {t(SummaryPageLocale.ATENTION)}
                      </Typography>
                    )}

                    {resumeData[platform].disabled === 0 && (
                      <Typography variant="body2" color="text.secondary">
                        {t(SummaryPageLocale.OK)}
                      </Typography>
                    )}

                    <Typography variant="body2" color="text.secondary">
                      <Box
                        component="strong"
                        color={
                          resumeData[platform].disabled === 0
                            ? "#4CAF50"
                            : "#EF5350"
                        }
                      >
                        {resumeData[platform].disabled === 0
                          ? resumeData[platform].enabled
                          : resumeData[platform].disabled}
                      </Box>{" "}
                      /{" "}
                      {resumeData[platform].enabled +
                        resumeData[platform].disabled}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Fragment>
          ))
        )}
      </Box>
    </Box>
  );
};

export default ResumeRow;
