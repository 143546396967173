import { createSlice } from "@reduxjs/toolkit";

export interface DataTableState {
  lastUpdateActiveOrder: number;
  lastAutorefresh: number;
}
const initialState: DataTableState = {
  lastUpdateActiveOrder: new Date().getTime(),
  lastAutorefresh: new Date().getTime(),
};

const dataTableSlice = createSlice({
  name: "datatable",
  initialState,
  reducers: {
    setLastUpdateActiveOrder: (state, action) => {
      state.lastUpdateActiveOrder = action.payload;
    },
    setTimeAutorefresh: (state, action) => {
      state.lastAutorefresh = action.payload;
    },
  },
});

export const { setLastUpdateActiveOrder, setTimeAutorefresh } =
  dataTableSlice.actions;
export default dataTableSlice.reducer;
