import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncState } from "../types";
import { SessionCookieGateway } from "../../gateways/cookie/account/session";
import { GetSessionUseCase } from "../../domain/useCases/account/getSession";
import { Session } from "../../domain/entities/Session";

interface SessionState {
  session: Session | null;
  status: AsyncState;
  error: string | null;
}

const initialState: SessionState = {
  session: null,
  status: AsyncState.IDLE,
  error: null,
};

export const getSession = createAsyncThunk("session/getSession", async () => {
  const repository = new SessionCookieGateway();
  const usecase = new GetSessionUseCase(repository);
  return usecase.execute();
});
export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    removeSession: (state, payload) => {},
  },
  extraReducers(builder) {
    builder.addCase(getSession.pending, (state) => {
      state.status = AsyncState.LOADING;
    });
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.status = AsyncState.SUCCESS;
      state.session = action.payload;
    });

    builder.addCase(getSession.rejected, (state, action) => {
      state.status = AsyncState.ERROR;
    });
  },
});

export default sessionSlice.reducer;
export const { removeSession } = sessionSlice.actions;
