import React from "react";

interface AIconProps {
  color?: string;
  width?: string;
}

const AIcon = (props: AIconProps): React.ReactElement => {
  const { color, width } = props;
  return (
    <svg
      width={!width ? "31" : width}
      height="32"
      viewBox="0 0 31 32"
      fill={!color ? "#ED6C02" : color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.5048 5.47253L7.80152 32H0.790527L12.6806 0H17.1422L16.5048 5.47253ZM23.7356 32L15.0103 5.47253L14.307 0H18.8125L30.7685 32H23.7356ZM23.34 20.0879V25.2527H6.43888V20.0879H23.34Z" />
    </svg>
  );
};

export default AIcon;
