import { Box, Chip, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/index";
import CloseIcon from "@mui/icons-material/Close";
import {
  changeCheckedChildrenResulSearch,
  changeCheckedResulSearch,
  ISearchResult,
  ResultType,
} from "../../../store/slices/filterSlice";
import { getCityName } from "../../utils/city";

const STORAGE_FILTER_KEY = "monitor.filters";

function ChipsFilter() {
  const dispatch = useDispatch();
  const { resultSearchSelected } = useSelector(
    (state: RootState) => state.filter
  );

  const getItemLocalStorage = (item: ISearchResult) => {
    let storageLocal = JSON.parse(
      localStorage.getItem(STORAGE_FILTER_KEY) || "[]"
    );

    const findIndex = storageLocal.findIndex(
      (s: ISearchResult) => s.name === item.name && s.type === item.type
    );

    return { storageLocal, findIndex };
  };

  const handleDelete = (item: ISearchResult) => {
    const { findIndex, storageLocal } = getItemLocalStorage(item);
    storageLocal.splice(findIndex, 1);
    localStorage.setItem(STORAGE_FILTER_KEY, JSON.stringify(storageLocal));

    dispatch(changeCheckedResulSearch({ item, checked: false }));
  };
  const handleDeleteChildren = (item: ISearchResult, indexChild: number) => {
    if (item.type === ResultType.CITY || item.type === ResultType.COUNTRY) {
      const { findIndex, storageLocal } = getItemLocalStorage(item);
      storageLocal[findIndex].children[indexChild].checked = false;

      if (
        storageLocal[findIndex].children.filter((i: any) => i.checked)
          .length === 0
      ) {
        storageLocal.splice(findIndex, 1);
      }
      localStorage.setItem(STORAGE_FILTER_KEY, JSON.stringify(storageLocal));
    }
    dispatch(
      changeCheckedChildrenResulSearch({ item, indexChild, checked: false })
    );
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={1} flexDirection={"row"}>
      {resultSearchSelected &&
        resultSearchSelected.map((item: ISearchResult) => {
          return item.children.some((child) => !child.checked) ? (
            item.children.map((child: ISearchResult, index) => {
              return (
                child.checked && (
                  <Chip
                    color="primary"
                    key={child.name}
                    label={
                      <Typography
                        variant="body1"
                        fontSize="16px"
                        textTransform="capitalize"
                      >
                        {child.type === ResultType.CITY
                          ? getCityName(child.name)
                          : child.name}
                      </Typography>
                    }
                    icon={
                      <IconButton
                        onClick={() => handleDeleteChildren(item, index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                )
              );
            })
          ) : (
            <Chip
              color="primary"
              key={item.name}
              label={
                <Typography
                  variant="body1"
                  fontSize="16px"
                  textTransform="capitalize"
                >
                  {item.type === ResultType.CITY
                    ? getCityName(item.name)
                    : item.name}
                </Typography>
              }
              icon={
                <IconButton onClick={() => handleDelete(item)}>
                  <CloseIcon />
                </IconButton>
              }
            />
          );
        })}
    </Box>
  );
}

export default ChipsFilter;
