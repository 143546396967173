import StoreGateway from "domain/interfaces/gateways/store.gateway";
import { StoreCampaing } from "../../../domain/entities/Store.entity";
import { API } from "aws-amplify";
import * as queries from "./../graphql/queries";
import { ListStoresCampaingsQuery } from "../types";
import { GraphQLResult } from "@aws-amplify/api-graphql";

class AmplifyStoreGateway implements StoreGateway {
  private items: Array<StoreCampaing> = [];
  private token: string | null | undefined = "";
  async getQueryPagination(token: string | undefined | null) {
    const query = (await API.graphql({
      query: queries.listStoreWithCampaigns,
      variables: {
        limit: 50, //limit row per pagination
        nextToken: token,
      },
    })) as GraphQLResult<ListStoresCampaingsQuery>;
    return await query;
  }

  async buildItems(token: any) {
    if (typeof token == "string" || Boolean(token)) {
      const queryResult: GraphQLResult<ListStoresCampaingsQuery> =
        await this.getQueryPagination(token);

      if (!queryResult.data?.listStores?.items) {
        throw new Error("AmplifyKitchenGateway.getStoreCampaings: query error");
      }

      const items: Array<StoreCampaing> =
        queryResult.data?.listStores?.items.map((item) => {
          try {
            return {
              ...item,
              campaigns: item.campaigns ? JSON.parse(item.campaigns) : null,
            };
          } catch {
            return { ...item, campaigns: null };
          }
        });
      this.items.push(...items);
      this.token = queryResult.data?.listStores?.nextToken;
      await this.buildItems(this.token);
    }
  }

  async getStoresWithCampaigns(): Promise<Array<StoreCampaing>> {
    await this.buildItems(this.token);
    return this.items;
  }
}

export default AmplifyStoreGateway;
