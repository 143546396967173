import { Session } from "../../../domain/entities/Session";
import { CanGetSession } from "../../../domain/interfaces/account/canGetSession";
import { CookieItem, getCookie } from "../../../presentation/utils/cookie";
import { TypeOfRole } from "../../../presentation/utils/role";

export class SessionCookieGateway implements CanGetSession {
  public getSession(): Session {
    const cookieUserId = getCookie(CookieItem.userId);
    const cookieLoginToken = getCookie(CookieItem.loginToken);
    const cookieRole = getCookie(CookieItem.role);

    if (!cookieUserId || !cookieLoginToken) {
      throw new Error("Cookie session not found");
    }

    const session: Session = {
      userId: cookieUserId,
      token: cookieLoginToken,
      role: cookieRole ?? TypeOfRole.COCINERO_CP,
    };

    return session;
  }
}
