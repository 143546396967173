import amplitude from "amplitude-js";

export enum AmplitudeEvent {
  DrawerOpenShopOnOff = "Abrir drawer tiendas on/off.",
  DrawerOpenOrdersActive = "Abrir drawer ordenes activas.",
  DrawerOpenCoverage = "Abrir drawer de cobertura.",
  DrawerOpenDiscounts = "Abrir drawer de descuentos.",
  HiddenColumns = "Click en la configuración de columnas visibiles.",
  ClickButtonFilter = "Click en el botón de filtros.",
}

const logEvent = (
  event: string,
  eventProperties?: Record<string, unknown>
): void => {
  amplitude.getInstance().logEvent(event, eventProperties);
};

const setUserProperties = (userProperties: Record<string, unknown>): void => {
  amplitude.getInstance().setUserProperties(userProperties);
};

const setUserIdAmplitude = (userId: string): void => {
  amplitude.getInstance().setUserId(userId);
};

const initAnalytics = (): void => {
  amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUDE_APY_KEY}`);
};

export { initAnalytics, logEvent, setUserIdAmplitude, setUserProperties };
