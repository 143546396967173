export enum SuppliesSummaryPageLocale {
  TITLE = "suppliessummary.page.title",
  DESCRIPTION = "suppliessummary.page.description",
  TOTALOFF = "suppliessummary.page.totaloff",
  TYPEAA = "suppliessummary.page.typeaa",
  TYPEA = "suppliessummary.page.typea",
  TYPEB = "suppliessummary.page.typeb",
  TYPEC = "suppliessummary.page.typec",
  KITCHEN = "suppliessummary.page.kitchen",
}
