export enum TypeOfRole {
  ADMIN = "ADMIN",
  QUALITY = "QUALITY",
  CITY_MANAGER = "CITY_MANAGER",
  COCINERO = "COCINERO",
  JEFE_COCINA = "JEFE_COCINA",
  COMPRAS = "COMPRAS",
  CONTABILIDAD = "CONTABILIDAD",
  COUNTRY_MANAGER = "COUNTRY_MANAGER",
  DATA = "DATA",
  ESTRATEGIA = "ESTRATEGIA",
  FINANZAS = "FINANZAS",
  HITS = "HITS",
  MARKETING = "MARKETING",
  OPS = "OPS",
  PEOPLE = "PEOPLE",
  PRODUCTO = "PRODUCTO",
  SOCIOS = "SOCIOS",
  TORRE = "TORRE",
  TECH = "TECH",
  NEW_VERTICALS = "NEW_VERTICALS",
  NOMINA = "NOMINA",
  EXPANSION = "EXPANSION",
  EXTERNAL = "EXTERNAL",
  FRANCHISE = "FRANCHISE",
  CATALOGUE = "CATALOGUE",
  TECH_OPS = "TECH_OPS",
  SOPORTE_CLIENTE = "SOPORTE_CLIENTE",
  OPS_EXCELLENTS = "OPS_EXCELLENTS",
  JEFE_CP = "JEFE_CP",
  COCINERO_CP = "COCINERO_CP",
  STORER = "STORER",
  INVENTORY_ANALYST = "INVENTORY_ANALYST",
  SEGUNDO_COCINA = "SEGUNDO_COCINA",
  SEGUNDO_CP = "SEGUNDO_CP",
}

export const roleList: { [role: string]: string } = {
  [TypeOfRole.ADMIN]: "Administrativo",
  [TypeOfRole.QUALITY]: "Calidad",
  [TypeOfRole.CITY_MANAGER]: "City Manager",
  [TypeOfRole.COCINERO]: "Cocina",
  [TypeOfRole.JEFE_COCINA]: "Jefe de Cocina",
  [TypeOfRole.COMPRAS]: "Compras",
  [TypeOfRole.CONTABILIDAD]: "Contabilidad",
  [TypeOfRole.COUNTRY_MANAGER]: "Country Manager",
  [TypeOfRole.DATA]: "Data",
  [TypeOfRole.ESTRATEGIA]: "Estrategia",
  [TypeOfRole.FINANZAS]: "Finanzas",
  [TypeOfRole.HITS]: "Hits",
  [TypeOfRole.MARKETING]: "Marketing",
  [TypeOfRole.OPS]: "Ops",
  [TypeOfRole.PEOPLE]: "People",
  [TypeOfRole.PRODUCTO]: "Producto",
  [TypeOfRole.SOCIOS]: "Socios",
  [TypeOfRole.TECH]: "Tech",
  [TypeOfRole.TECH_OPS]: "Tech ops",
  [TypeOfRole.SOPORTE_CLIENTE]: "Soporte al cliente",
  [TypeOfRole.NEW_VERTICALS]: "New Verticals",
  [TypeOfRole.NOMINA]: "Nomina",
  [TypeOfRole.EXPANSION]: "Expansion",
  [TypeOfRole.TORRE]: "Torre",
  [TypeOfRole.EXTERNAL]: "Externo",
  [TypeOfRole.FRANCHISE]: "Franquicia",
  [TypeOfRole.CATALOGUE]: "Catalogo",
  [TypeOfRole.OPS_EXCELLENTS]: "Ops Excellents",
  [TypeOfRole.JEFE_CP]: "Jefe de CP",
  [TypeOfRole.COCINERO_CP]: "Cocinero de CP",
  [TypeOfRole.STORER]: "Almacenista",
  [TypeOfRole.INVENTORY_ANALYST]: "Analista de inventario",
  [TypeOfRole.SEGUNDO_COCINA]: "Segundo de cocina",
  [TypeOfRole.SEGUNDO_CP]: "Segundo de CP",
};
