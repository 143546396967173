import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Box, Popover } from "@mui/material";
import { FunctionComponent } from "react";

const ButtonResumen = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderWidth: "1px",
  borderStyle: "solid",
  justifyContent: "space-between",
}));

interface ButtonResumenPopoverProps {
  children: React.ReactNode;
}
const ButtonResumenPopover: FunctionComponent<ButtonResumenPopoverProps> = ({
  children,
}) => {
  const openPopover = (state: any) => (event: any) => {
    bindTrigger(state).onClick(event);
  };
  return (
    <PopupState variant="popover" popupId="button-resumen-popover">
      {(popupState) => (
        <>
          <ButtonResumen
            color="primary"
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              width: { xs: "227px" },
              height: "40px",
            }}
            onClick={openPopover(popupState)}
            onTouchStart={openPopover(popupState)}
          >
            <Typography
              textTransform="capitalize"
              variant="h5"
              fontSize="20px"
              fontWeight="400"
            >
              Resumen
            </Typography>
          </ButtonResumen>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              marginTop: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection={"column"}
              gap={1}
              sx={{ width: "370px" }}
            >
              {children}
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ButtonResumenPopover;
