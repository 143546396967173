import { Box, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import NoFoodOutlinedIcon from "@mui/icons-material/NoFoodOutlined";
import { useTranslation } from "react-i18next";
import { SuppliesSummaryPageLocale } from "../../../../domain/interfaces/i18n/suppliesSummary.interface";
import AAIcon from "../utils/icons/AAIcon";
import AIcon from "../utils/icons/AIcon";
import BIcon from "../utils/icons/BIcon";
import CIcon from "../utils/icons/CIcon";

const TotalComponent: FunctionComponent<{
  color: string;
  atention: number;
  total: number;
}> = ({ color, atention, total }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", fontSize: "16px" }}>
      <Typography sx={{ fontWeight: "700", color }}>{atention}</Typography>
      <Typography sx={{ mx: "3px" }}>/</Typography>
      <Typography sx={{ fontWeight: "300" }}>{total}</Typography>
    </Box>
  );
};
const ResumeRow = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" overflow="auto" width="100%">
      <Box
        display="flex"
        alignItems="center"
        overflow="auto"
        flexGrow={1}
        sx={{
          flexDirection: { xs: "row" },
          flexWrap: { xs: "wrap" },
          gap: { xs: 0, md: 3 },
        }}
      >
        <Box
          borderRadius={1}
          bgcolor="white"
          display="flex"
          flex={1}
          gap={2}
          alignItems="center"
          justifyContent="center"
          border="1px solid #F1EFEF"
          minHeight="50px"
          sx={{ ml: { md: "5px" } }}
        >
          <Box display="flex" gap={0.5}>
            <NoFoodOutlinedIcon sx={{ fontSize: "30px" }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" color="text.secondary">
              {t(SuppliesSummaryPageLocale.TOTALOFF)}
            </Typography>
            <TotalComponent total={2800} atention={67} color="#000" />
          </Box>
        </Box>
        <Box
          borderRadius={1}
          bgcolor="white"
          display="flex"
          flex={1}
          gap={2}
          alignItems="center"
          justifyContent="center"
          border="1px solid #F1EFEF"
          minHeight="50px"
          sx={{ ml: { md: "5px" } }}
        >
          <Box display="flex" gap={0.5}>
            <AAIcon />
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" color="text.secondary">
              {t(SuppliesSummaryPageLocale.TYPEAA)}
            </Typography>
            <TotalComponent atention={10} total={280} color="#E65100" />
          </Box>
        </Box>
        <Box
          borderRadius={1}
          bgcolor="white"
          display="flex"
          flex={1}
          gap={2}
          alignItems="center"
          justifyContent="center"
          border="1px solid #F1EFEF"
          minHeight="50px"
          sx={{ ml: { md: "5px" } }}
        >
          <Box display="flex" gap={0.5}>
            <AIcon />
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" color="text.secondary">
              {t(SuppliesSummaryPageLocale.TYPEA)}
            </Typography>
            <TotalComponent atention={12} total={280} color="#ED6C02" />
          </Box>
        </Box>
        <Box
          borderRadius={1}
          bgcolor="white"
          display="flex"
          flex={1}
          gap={2}
          alignItems="center"
          justifyContent="center"
          border="1px solid #F1EFEF"
          minHeight="50px"
          sx={{ ml: { md: "5px" } }}
        >
          <Box display="flex" gap={0.5}>
            <BIcon />
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" color="text.secondary">
              {t(SuppliesSummaryPageLocale.TYPEB)}
            </Typography>
            <TotalComponent atention={20} total={280} color="#01579B" />
          </Box>
        </Box>
        <Box
          borderRadius={1}
          bgcolor="white"
          display="flex"
          flex={1}
          gap={2}
          alignItems="center"
          justifyContent="center"
          border="1px solid #F1EFEF"
          minHeight="50px"
          sx={{ ml: { md: "5px" } }}
        >
          <Box display="flex" gap={0.5}>
            <CIcon />
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" color="text.secondary">
              {t(SuppliesSummaryPageLocale.TYPEC)}
            </Typography>
            <TotalComponent atention={25} total={280} color="#0288D1" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResumeRow;
