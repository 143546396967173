import Kitchen from "../../entities/Kitchen.enitity";
import { KitchenProcessStrategy } from "../../interfaces/strategy/kitchen.strategy";

class OrderKitchenByAtentionUseCase implements KitchenProcessStrategy {
  execute(data: Kitchen[]): Kitchen[] {
    return data.sort((a, b) => b.countAtention - a.countAtention);
  }
}

export default OrderKitchenByAtentionUseCase;
