import { createAsyncThunk } from "@reduxjs/toolkit";

import Kitchen from "domain/entities/Kitchen.enitity";
import { getKitchenState } from "../../commons";
import { StoreCampaing } from "../../../domain/entities/Store.entity";
import AmplifyStoreGateway from "../../../gateways/amplify/gateways/store.gateway";
import GetStoresStateUseCaseImpl from "../../../domain/useCases/store/getStoresState.usecase";

export const getKitchensState = createAsyncThunk<Array<Kitchen>, string>(
  "storeState/getKitchensState",
  async (state: string) => {
    const itemsKitchen = await getKitchenState();
    if (state !== "ALL") {
      return itemsKitchen.filter(
        (item) => item.type === null && item.country === state
      );
    } else {
      return itemsKitchen.filter((item) => item.type === null);
    }
  }
);

export const getStoresWithCampaings = createAsyncThunk<Array<StoreCampaing>>(
  "storeState/getStoreCampaigns",
  async () => {
    const gateway = new AmplifyStoreGateway();
    const usecase = new GetStoresStateUseCaseImpl(gateway);
    const items = await usecase.exec();
    return items;
  }
);
